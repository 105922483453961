import { makeStyles, StepConnector, withStyles } from '@material-ui/core';

const useStyles = makeStyles(({ theme }) => ({
  stepRoot: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  root: {
    width: '100%',
    '& .MuiStepLabel-iconContainer': {
      height: 50,
      alignItems: 'center',
    },
    '& .MuiStepper-horizontal': {
      padding: '12px 0 24px 0'
    }
  },
  button: {
    marginRight: 12,
  },
  instructions: {
    marginTop: 12,
    marginBottom: 12,
  },
  labelStep: {
    fontSize: 10,
    fontWeight: 700,
    color: '#242424',
  },
  labelStepActive: {
    color: '#1459FF'
  },
  labelStepDisabled: {
    color: '#CFCFD4!important'
  },
  labelStepDate: {
    fontSize: 10,
    fontWeight: 400,
    color: '#7B7F82'
  },
  buttonActions: {
    color: '#242424',
    '&&': {
      fontSize: 12,
      fontWeight: 600,
      textTransform: 'none',
      borderRadius: 4,
      border: '1px solid',
      padding: '6px 12px',
    }
  },
  buttonActionsBlue: {
    width: '100%',
    borderColor: '#1459FF',
    color: '#1459FF'
  }
}));

export const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 23,
    '& .MuiStep-completed': {
      backgroundColor: 'red'
    }
  },
  active: {
    '& $line': {
      backgroundColor: '#1459FF'
    },
  },
  completed: {
    height: 50,
    '& $line': {
      backgroundColor: '#1459FF'
    },
  },
  line: {
    width: 'calc(100% + 32px)',
    marginLeft: '-18px',
    height: 3,
    border: 0,
    backgroundColor: '#ccc',
    borderRadius: 1,
  },
})(StepConnector);

export const useColorlibStepIconStyles = makeStyles({
  root: {
    height: 16,
    width: 16,
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    width: 40,
    height: 40,
    backgroundColor: '#1459FF'
  },
  completed: {
    backgroundColor: '#1459FF'
  },
});


export default useStyles;
