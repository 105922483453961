import React, { useRef, useState } from 'react';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Typography from '@material-ui/core/Typography';

import { Fade } from '@material-ui/core';
import { toMoney } from '../../../../../../shared/utils/parsers';

import useStyles from './styles';
import PropTypes from 'prop-types';

const OrderProducts = ({ products }) => {
  const classes = useStyles();

  const {
    root,
    slider,
    sliderWrapper,
    navButton,
    productTitle,
    productPrice
  } = classes;

  const sliderRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const [isHovered, setIsHovered] = useState(false);

  const handleScrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft -= 110;
    }
  };

  const handleScrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft += 110;
    }
  };

  const onMouseDown = (e) => {
    const slider = sliderRef.current;
    setIsDragging(true);
    slider.style.cursor = 'grabbing';

    setStartX(e.pageX - slider.offsetLeft);
    setScrollLeft(slider.scrollLeft);
  };

  const onMouseLeave = () => {
    if (!isDragging) return;
    setIsDragging(false);
    if (sliderRef.current) {
      sliderRef.current.style.cursor = 'grab';
    }
  };

  const onMouseUp = () => {
    setIsDragging(false);
    if (sliderRef.current) {
      sliderRef.current.style.cursor = 'grab';
    }
  };

  const onMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();

    const slider = sliderRef.current;
    const x = e.pageX - slider.offsetLeft;
    const walk = x - startX;
    slider.scrollLeft = scrollLeft - walk;
  };


  return (
    <div
      className={root}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={sliderWrapper}>
        <Fade in={isHovered}>
          <IconButton
            className={navButton}
            onClick={handleScrollLeft}
          >
            <ArrowBackIosIcon />
          </IconButton>
        </Fade>

        <div
          className={slider}
          ref={sliderRef}
          onMouseDown={onMouseDown}
          onMouseLeave={onMouseLeave}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
        >
          <Box display="flex" justifyContent="center" style={{gap: "12px"}}>
            {products.map((product) => (
              <Box display="flex" key={product.id} width={220}>
                <Box>
                  <img
                    style={{width: '50px', height: '50px'}}
                    src={`https://img.lojadomecanico.com.br/IMAGENS/${product.foto}`}
                  />
                </Box>
                <Box>
                  <Typography className={productTitle}>{product.nomeProduto}</Typography>
                  <Typography className={productPrice}>{product.quantidade} Und. - {toMoney(product.preco)}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </div>

        <Fade in={isHovered}>
          <IconButton
            className={navButton}
            onClick={handleScrollRight}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </Fade>
      </div>
    </div>
  );
}

OrderProducts.propTypes = {
  products: PropTypes.array,
};

export default OrderProducts;