import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  Box, Button,
  CardActions,
  CardContent,
  Grid,
} from '@material-ui/core';
import {
  change,
  Field,
  formValueSelector,
  reduxForm,
} from 'redux-form';
import * as PropTypes from 'prop-types';
import useStyles from './styles';
// eslint-disable-next-line import/no-cycle
import { LinkedAccounts } from './_components';
import { InputCredentialRender } from '../../../shared/fields';
import { push } from '../../../history';
import { Card, Divider } from '../_components';
import { clientSignInAction, setClientAction } from './actions';
import { toCNPJ, toCPF, toCurrency } from '../../../shared/utils/parsers';
import businessUnitTypes from '../../../shared/utils/businessUnitTypes';
import { getFilialVendedor, getUnidadeDeNegocio } from '../../../shared/services/app';
import { newCheckoutAction } from '../../checkout/actions';
import { Snackbar } from '../../../shared/components/algolia';
import {
  putMainAddressPFAction,
  putMainAddressPJAction,
} from '../../client/pages/addressesPage/actions';

const Login = ({
  client,
  clients,
  isLoading,
  clientSignIn,
  credential,
  user,
  newCheckout,
  putMainAddressPF,
  putMainAddressPJ,
}) => {
  const {
    btn,
    btnBox,
    cardGrid,
    content,
    fieldGrid,
    form,
    infoUserBox,
    infoUserMainBox,
    infoUserContent,
    infoUserTitle,
    input,
    locationIcon,
    loginActions,
    userIcon,
    vouchersIcon,
  } = useStyles();

  const [businessUnit] = useState(getUnidadeDeNegocio || 2);
  const [manyAccounts, setManyAccounts] = useState(false);
  const [filialUserLinked, setFilialUserLinked] = useState(0);

  const [alert, setAlert] = useState({
    open: false,
    message: 'Produto adicionado ao carrinho com sucesso.',
    severity: 'success',
  });

  const [infoLogin, setInfoLogin] = useState({
    title: 'Digite o e-mail, CPF ou CNPJ do Cliente',
    subtitle: '',
    icon: '',
  });

  const infoUser = client ? [{
    title: 'Meus vales:',
    icon: <span className={vouchersIcon} />,
    textPrimary: `Você possui <b>${toCurrency(client.valeTotal)}</b>`,
    textSecondary: 'disponível para uso',
  }, {
    title: 'Endereço:',
    icon: <span className={locationIcon} />,
    textPrimary: `${client.logradouro || '-'}, ${client.numero || '-'}`,
    textSecondary: `${client.bairro || '-'}, ${client.cidade || '-'}/${client.uf || '-'}`,
  }] : [];

  const createClientAccount = () => push('/register');
  const dispatch = useDispatch();

  const handleNewCheckout = () => {
    const productInitialLogin = localStorage.getItem('productInitialToLogin');
    const productInitial = productInitialLogin ? JSON.parse(productInitialLogin) : null;

    if (productInitial) {
      const checkoutInfo = {
        idVendedor: user.idVendedor,
        idCliente: client.codCliente,
        idFilial: filialUserLinked,
      };

      newCheckout({ checkout: checkoutInfo, product: productInitial });
      localStorage.removeItem('productInitialToLogin');
    }
  };

  const confirmAddress = async () => {
    if (client.tipoPessoa === 'J') {
      const address = {
        logradouro: client.logradouro,
        cidade: client.cidade,
        bairro: client.bairro,
        numero: client.numero,
        estado: client.uf,
        bitPrincipal: client.bitPrincipal,
        bitInativo: client.bitInativo,
        idFilial: client.idEndereco,
        razaoSocial: client.nomeRazaoSocial,
        tipoPessoa: client.tipoPessoa,
        codCliente: client.codCliente,
        cnpj: client.cpfCnpj,
        confirmAddress: true,
      };

      await putMainAddressPJ(client.codCliente, address);
    } else {
      const address = {
        logradouro: client.logradouro,
        cidade: client.cidade,
        bairro: client.bairro,
        numero: client.numero,
        uf: client.uf,
      };

      await putMainAddressPF(client.idEndereco, address);
    }

    await push('/');
  };

  const redirectToAlterAddress = async () => {
    if (!client.bitInativo && client.logradouro.length) {
      if (client.tipoPessoa === 'J') {
        const address = {
          logradouro: client.logradouro,
          cidade: client.cidade,
          bairro: client.bairro,
          numero: client.numero,
          estado: client.uf,
          bitPrincipal: client.bitPrincipal,
          bitInativo: client.bitInativo,
          idFilial: client.idEndereco,
          razaoSocial: client.nomeRazaoSocial,
          tipoPessoa: client.tipoPessoa,
          codCliente: client.codCliente,
          cnpj: client.cpfCnpj,
          confirmAddress: true,
        };

        await putMainAddressPJ(client.codCliente, address);
      } else {
        const address = {
          logradouro: client.logradouro,
          cidade: client.cidade,
          bairro: client.bairro,
          numero: client.numero,
          uf: client.uf,
        };

        await putMainAddressPF(client.idEndereco, address);
      }
    }
    await push('/client-space/addresses');
  };

  const handleSignIn = () => clientSignIn({ credential });

  useEffect(() => {
    if (client) {
      dispatch(change('login/client', 'credential', ''));
      setInfoLogin({
        title: `Olá, ${(client.nomeRazaoSocial || 'Cliente sem nome')}`.toUpperCase(),
        subtitle: (
          <>
            <span className={userIcon}>
              {client.tipoPessoa === 'F'
                ? 'Pessoa Física' : 'Pessoa Jurídica'}
            </span>
            <span>
              {`${client.tipoPessoa === 'F'
                ? (`CPF: ${toCPF(client.cpfCnpj)}`)
                : (`CNPJ: ${toCNPJ(client.cpfCnpj)}`)}`}
            </span>
          </>
        ),
        icon: client.tipoPessoa === 'J' ? 'corporate-person' : '',
      });

      if (client.confirmAddress) {
        push('/');
      }
    } else {
      setInfoLogin({
        title: 'Digite o e-mail, CPF ou CNPJ do Cliente',
        subtitle: '',
        icon: '',
      });
    }
  }, [client, credential]);

  useEffect(() => {
    if (clients && !client) {
      setInfoLogin({
        title: 'O Cliente tem mais de uma conta vinculada',
        subtitle: 'Selecione uma para continuar.',
        icon: '',
      });
    }
  }, [clients]);

  useEffect(() => {
    setManyAccounts(clients ? (clients.length && !client) : false);
  }, [clients, client]);

  useEffect(() => {
    if (Number(businessUnit) === businessUnitTypes.LOJAS_FISICAS) {
      const res = Number(getFilialVendedor() || 2);
      setFilialUserLinked(res);
    }
  }, []);

  useEffect(() => {
    if (client && client.confirmAddress && !client.loggedByCheckoutDetails) {
      handleNewCheckout();
    }
  }, [client]);

  useEffect(() => {
    if (client && client.loggedByCheckoutDetails) {
      localStorage.removeItem('productInitialToLogin');
    }
  }, [client]);

  return (
    <Grid className={cardGrid} onClick={(e) => e.stopPropagation()}>
      <Card
        title={infoLogin.title}
        subtitle={infoLogin.subtitle}
        icon={infoLogin.icon}
        manyAccounts={manyAccounts}
      >
        <form
          className={form}
          onSubmit={(e) => {
            if (!client && !clients) {
              handleSignIn();
            }
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <CardContent classes={{ root: `${content} ${manyAccounts && 'noPadding'}` }}>
            <Box>
              <Grid className={fieldGrid} item xs={12}>
                {
                  // eslint-disable-next-line no-nested-ternary
                  client ? (
                    infoUser.map(({
                      title,
                      icon,
                      textPrimary,
                      textSecondary,
                    }) => (
                      <Box
                        className={infoUserMainBox}
                      >
                        <span
                          className={infoUserTitle}
                        >
                          {title}
                        </span>
                        <Box className={infoUserBox}>
                          {icon}
                          <Box className={infoUserContent}>
                            <span
                              dangerouslySetInnerHTML={{ __html: textPrimary }}
                            />
                            <span>
                              {textSecondary}
                            </span>
                          </Box>
                        </Box>
                      </Box>
                    ))
                  ) : (
                    clients ? (
                      <LinkedAccounts accounts={clients} />
                    ) : (
                      <>
                        <label>Digite seu CPF/CNPJ ou e-mail</label>
                        <Field
                          name="credential"
                          component={InputCredentialRender}
                          type="input"
                          placeholder="ex: 100.100.100-10"
                          classes={input}
                          disabled={isLoading}
                          autocomplete="on"
                        />
                      </>
                    )
                  )
                }
              </Grid>
            </Box>
          </CardContent>
          <CardActions className={`${loginActions} ${(manyAccounts) && 'actionsCustom'}`}>
            <Box className={`${btnBox}`}>
              {
                client ? (
                  <>
                    {client.logradouro.length > 0 && (
                      <Button
                        classes={{ root: btn }}
                        onClick={confirmAddress}
                      >
                        Confirmar endereço
                      </Button>
                    )}
                    <Button
                      classes={{ root: `${btn} ${client.logradouro.length && 'outlined'}` }}
                      onClick={redirectToAlterAddress}
                    >
                      {client.logradouro.length ? 'Alterar' : 'Cadastrar'} endereço
                    </Button>
                  </>
                ) : (
                  <>
                    {
                      !clients && (
                        <Button
                          classes={{ root: btn }}
                          type="submit"
                          disabled={isLoading}
                        >
                          Entrar
                        </Button>
                      )
                    }
                    <Divider>ou</Divider>
                    <Button
                      classes={{ root: `${btn} outlined` }}
                      onClick={createClientAccount}
                      disabled={isLoading}
                    >
                      Criar nova conta
                    </Button>
                  </>
                )
              }
            </Box>
          </CardActions>
        </form>
        <Snackbar alertOptions={alert} setAlertOptions={setAlert} />
      </Card>
    </Grid>
  );
};

Login.defaultProps = {
  client: null,
  clients: null,
  credential: '',
};

Login.propTypes = {
  client: PropTypes.instanceOf(Object),
  clients: PropTypes.instanceOf(Array),
  user: PropTypes.instanceOf(Object).isRequired,
  isLoading: PropTypes.bool.isRequired,
  clientSignIn: PropTypes.func.isRequired,
  credential: PropTypes.string,
  newCheckout: PropTypes.func.isRequired,
  putMainAddressPJ: PropTypes.func.isRequired,
  putMainAddressPF: PropTypes.func.isRequired,
};

const selector = formValueSelector('login/client');

const mapState = (state) => {
  const {
    client,
    clients,
    isLoading,
  } = state.authClient;

  return {
    client,
    clients,
    isLoading,
    credential: selector(state, 'credential'),
    user: state.auth.login.user,
  };
};

export default connect(mapState, {
  clientSignIn: clientSignInAction,
  setClient: setClientAction,
  newCheckout: newCheckoutAction,
  putMainAddressPF: putMainAddressPFAction,
  putMainAddressPJ: putMainAddressPJAction,
})(reduxForm({
  form: 'login/client',
})(Login));
