import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import useStyles from './styles';

const ModalErrorInformation = ({ isCancelled, message, onClose }) => {
  const classes = useStyles();

  const { modalInconsistency } = classes;

  return (
    <Dialog open={message} className={modalInconsistency} onClose={onClose}>
      <DialogTitle>{isCancelled ? 'Pedido Cancelado' : 'Painel de inconsistência'}</DialogTitle>
      <DialogContent>
        {message}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalErrorInformation;