import { makeStyles } from '@material-ui/core';
import { FiltersIcon } from '../../../../assets/algolia';

const useStyles = makeStyles(({ breakpoints }) => ({
  filterGrid: {
    position: 'absolute',
    width: '100%',
    right: 0,
    zIndex: 1,
    background: '#E3E3E7',
    // top: 128,
    top: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  filterBoxMain: {
    background: '#EFEFEF',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    position: 'fixed',
    gap: 30,
    padding: 20,

    '&.hidden': {
      padding: '20px !important',
    },

    [breakpoints.down(1400)]: {
      width: '55%',
      justifyContent: 'center',
    },

    [breakpoints.between(1400, 1999)]: {
      padding: '20px 40vh',
    },

    [breakpoints.up(1999)]: {
      padding: '20px 300px',
    },

    [breakpoints.down(1800)]: {
      flexDirection: 'column',
    },
  },
  sortAndCloseBtns: {
    display: 'flex',
    gap: 20,
  },
  checkboxListBox: {
    flexWrap: 'wrap',
    display: 'flex',
    gap: '10px 30px',

    [breakpoints.down('sm')]: {
      marginTop: 10,
    },

    [breakpoints.down('md')]: {
      gap: 10,
    },
  },
  filtersBox: {
    display: 'flex',
    alignItems: 'center',
    gap: 30,

    [breakpoints.down('sm')]: {
      gap: 0,
      flexDirection: 'column',
      alignItems: 'start',
    },
  },
  optionBtn: {
    width: 40,
    height: 40,
    backgroundColor: '#FFFFFF',
    border: '1px solid #CFCFD4',
    borderRadius: 8,
    padding: 8,

    '&.selected': {
      background: '#FE5000',
      border: 0,
      color: '#FFFFFF',
    },

    '& svg': {
      width: 30,
      height: 30,
    },
  },
  boxMain: {
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
  },
  brandsBox: {
    gap: 10,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',

    '& span:first-of-type': {
      fontSize: 14,
      lineHeight: '14px',
      fontWeight: 600,
    },

    [breakpoints.down('md')]: {
      display: 'none',
    },
  },
  brandBox: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    borderRadius: 8,
    background: '#FFFFFF',
    border: '1px solid #CFCFD4',
    padding: '8px 16px',

    '& span:first-child': {
      lineHeight: '21px',
      gap: 4,
    },

    '& span:last-child': {
      fontSize: 10,
      fontWeight: 400,
      lineHeight: '15px',
    },

    '&.selected': {
      border: '1px solid #FE5000',
      color: '#FE5000',
      background: '#FFF0EA',
    },
  },
  filtersBtn: {
    background: `url(${FiltersIcon})`,
    backgroundRepeat: 'no-repeat',
    minWidth: 40,
    height: 40,
    backgroundPosition: 'center',
    border: '1px solid #FE5000',
  },
}));

export default useStyles;
