import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Paper,
  Popover,
} from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import useStyles from './styles';
import { push } from '../../../../../history';
import {
  clientLogoutAction,
  clientLogoutCatalogoAction,
} from '../../../../authClient/login/actions';
import { getClient } from '../../../../../shared/services/authClient';
import {
  putMainAddressPFAction,
  putMainAddressPJAction,
} from '../../../../client/pages/addressesPage/actions';
import { Divider } from '../../../../authClient/_components';
import { resetSearchAction } from '../../../actions';

const ClientMenu = ({
  client,
  clientLogout,
  clientLogoutCatalogo,
  putMainAddressPF,
  putMainAddressPJ,
  resetSearch,
}) => {
  const param = window.location.pathname.split('/');
  const isListAddressesPage = `${param[1]}/${param[2]}` === 'client-space/addresses';
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const clientMenuRef = useRef(null);

  const {
    clientMenuBoxHeader,
    clientMenuBox,
    arrowIcon,
    arrowToggleBtn,
    customerSpace,
    customerSpacePopover,
    customerSpacePopoverPaper,
    customerSpacePaper,
    arrowPopover,
    clientSignInBtn,
    clientName,
    avatarMobile,
  } = classes;

  const handleOpen = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const logout = () => {
    handleOpen();
    clientLogout();
    clientLogoutCatalogo();
    push('/client-login');
  };

  const redirectClientSpace = (tabName) => {
    handleOpen();
    push(`/client-space/${tabName}`);
  };

  const handleAddress = async () => {
    if (!isListAddressesPage) {
      if (client.tipoPessoa === 'J') {
        const address = {
          logradouro: client.logradouro,
          cidade: client.cidade,
          bairro: client.bairro,
          numero: client.numero,
          estado: client.uf,
          bitPrincipal: client.bitPrincipal,
          bitInativo: client.bitInativo,
          idFilial: client.idEndereco,
          razaoSocial: client.nomeRazaoSocial,
          tipoPessoa: client.tipoPessoa,
          codCliente: client.codCliente,
          cnpj: client.cpfCnpj,
          confirmAddress: true,
        };

        if (client.idEndereco) {
          await putMainAddressPJ(client.codCliente, address);
        }
      } else {
        const address = {
          logradouro: client.logradouro,
          cidade: client.cidade,
          bairro: client.bairro,
          numero: client.numero,
          uf: client.uf,
        };

        if (client.idEndereco) {
          await putMainAddressPF(client.idEndereco, address);
        }
      }
    }
    await redirectClientSpace('addresses');
  };

  const redirectSignIn = () => push('/client-login');

  useEffect(() => {
    if (client && !client.confirmAddress) {
      setOptions([{ title: 'Sair da Conta', className: 'logout', onClick: logout }]);
    } else {
      setOptions([
        { title: 'Espaço do Cliente', onClick: () => redirectClientSpace('registration-data') },
        // { title: 'Dados Cadastrais', className: 'user-settings', onClick: () => redirectClientSpace('registration-data') },
        { title: 'Endereços', className: 'location', onClick: handleAddress },
        // { title: 'Pedidos do Cliente', className: 'orders', onClick: () => redirectClientSpace('client-orders') },
        { title: 'Cupons/Vales', className: 'vouchers', onClick: () => redirectClientSpace('coupons-and-vouchers') },
        { title: 'Sair da Conta', className: 'logout', onClick: logout },
      ]);
    }
  }, [client]);

  return (
    <Grid>
      <Box className={clientMenuBoxHeader}>
        <Box className={clientMenuBox}>
          <span>Cliente</span>
          {client ? (
            <>
              <span className={clientName}>
                {(client.nomeRazaoSocial || 'Cliente sem nome').toUpperCase()}
              </span>
              <span className={avatarMobile}>
                {(client.nomeRazaoSocial ? client.nomeRazaoSocial[0] : '-').toUpperCase()}
              </span>
            </>
          ) : (
            <Button
              className={clientSignInBtn}
              onClick={redirectSignIn}
            >
              Logar Cliente
            </Button>
          )}
        </Box>
        {!!client && (
        <Button
          ref={clientMenuRef}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleOpen}
          className={arrowToggleBtn}
        >
          <span className={`${arrowIcon} ${open && 'open'}`} />
        </Button>
        )}
      </Box>
      <Popover
        classes={{
          root: customerSpacePopover,
          paper: customerSpacePopoverPaper,
        }}
        open={open}
        anchorEl={clientMenuRef.current}
        onClose={handleOpen}
        elevation={0}
      >
        <span className={arrowPopover} />
        <Paper className={customerSpacePaper}>
          {
              options.map(({ title, className, onClick }, index) => (
                <Box key={title}>
                  {options.length - 1 === index && <Divider />}
                  <Button
                    className={customerSpace}
                    onClick={() => {
                      onClick();
                      resetSearch(true);
                    }}
                  >
                    <span className={className} />
                    <span>{title}</span>
                  </Button>
                </Box>
              ))
            }
        </Paper>
      </Popover>
    </Grid>
  );
};

ClientMenu.defaultProps = {
  client: null,
};

ClientMenu.propTypes = {
  client: PropTypes.instanceOf(Object),
  clientLogout: PropTypes.func.isRequired,
  clientLogoutCatalogo: PropTypes.func.isRequired,
  putMainAddressPF: PropTypes.func.isRequired,
  putMainAddressPJ: PropTypes.func.isRequired,
};

const mapState = (state) => {
  let { client } = state.authClient;
  client = getClient() || client;

  return ({ client });
};

export default connect(mapState, {
  clientLogout: clientLogoutAction,
  clientLogoutCatalogo: clientLogoutCatalogoAction,
  putMainAddressPJ: putMainAddressPJAction,
  putMainAddressPF: putMainAddressPFAction,
  resetSearch: resetSearchAction,
})(ClientMenu);
