import { makeStyles } from '@material-ui/core';
import {
  CloseIcon,
  FiltersIcon,
  PushPinActiveIcon,
  PushPinInactiveIcon,
  SearchIcon,
  TrashIcon,
} from '../../../../assets/algolia';

const useStyles = makeStyles(({ breakpoints }) => ({
  searchBox: {
    height: 48,
    display: 'flex',
  },
  searchInput: {
    fontFamily: ['Poppins', 'sans-serif'],
    fontSize: 14,
    padding: '8px 16px',
    borderRadius: '8px 0 0 8px',
    border: '1px solid #E3E3E7',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '&::-webkit-search-decoration, &::-webkit-search-cancel-button, &::-webkit-search-results-button, &::-webkit-search-results-decoration': {
      '-webkit-appearance': 'none',
    },
    '&:focus': {
      outline: 'none',
    },
    [breakpoints.down('md')]: {
      width: 200,
    },
    [breakpoints.between('lg', 'lg')]: {
      width: 325,
    },
    [breakpoints.up('xl')]: {
      width: 680,
    },
  },
  searchButton: {
    borderRadius: '0 8px 8px 0',
    border: 'none',
    width: 56,
    backgroundColor: '#001A72',
    backgroundImage: `url(${SearchIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 24,
  },
  hitsPopper: {
    width: '100%',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    backgroundColor: '#EFEFEF',
    zIndex: 1,
  },
  hitsPaper: {
    height: '100%',
    paddingTop: 80,
    backgroundColor: '#EFEFEF',
    marginLeft: 64,
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    paddingBottom: 25,

    '&.MuiPaper-elevation1': {
      boxShadow: 'none',
    },

    '&.hidden': {
      gridTemplateColumns: 'auto',
    },

    [breakpoints.down(1400)]: {
      gridTemplateColumns: '1.5fr 2fr',
    },

    [breakpoints.between(1400, 1500)]: {
      gridTemplateColumns: '1fr 2fr',
    },

    [breakpoints.up(2000)]: {
      gridTemplateColumns: '0.5fr 2fr',
    },
  },
  searchBoxContainer: {
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  filtersMenuMain: {
    position: 'relative',
    height: '100%',
    zIndex: 2,

    '&.hidden': {
      display: 'none',
    },
  },
  filtersMenu: {
    position: 'fixed',
    display: 'flex',
    height: '100%',
    overflowY: 'scroll',
    background: '#FFFFFF',
    borderRight: '1px solid #CFCFD4',

    '&::-webkit-scrollbar': {
      width: 8,
      background: '#FFFFFF',
      borderLeft: '1px solid #CFCFD4',
      borderColor: '#CFCFD4',
    },

    '&::-webkit-scrollbar-track': {
      borderRadius: 10,
    },

    '&::-webkit-scrollbar-thumb': {
      background: '#CFCFD4',
      borderRadius: 10,

      '&:hover': {
        background: '#909599',
      },
    },
  },
  filterBox: {
    width: 364,
    paddingBottom: 30,

    '& ul': {
      listStyle: 'none',
      padding: '12px 12px 8px 12px',
      margin: 0,
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
    },

    [breakpoints.down('sm')]: {
      paddingBottom: 130,
    },
  },
  borderHeader: {
    borderBottom: '1px solid #CFCFD4',
    position: 'fixed',
    width: 364,
    backgroundColor: 'white',
    zIndex: 1,
  },
  [breakpoints.down('xs')]: {
    borderHeader: {
      width: 300,
    },
    filterBox: {
      width: 300,
    },
  },
  filterHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 12px',
    alignItems: 'center',
  },
  filtersIcon: {
    fontSize: '20px',
    lineHeight: '30px',
    fontWeight: 600,
    '&:before': {
      content: '""',
      background: `url(${FiltersIcon})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      padding: '1px 12px',
      marginRight: 8,
    },
  },
  clearAndClose: {
    display: 'flex',
    alignItems: 'center',
    gap: 20,
  },
  filterBody: {
    padding: '72px 0 50px',
  },
  clearRefinements: {
    backgroundColor: '#FFFFFF',
    border: 0,
    color: '#2296F3',
    fontSize: 14,
    lineHeight: '21px',
    textDecoration: 'underline',
    cursor: 'pointer',
    fontFamily: ['Poppins', 'sans serif'],
    fontWeight: 600,

    '&:before': {
      content: '""',
      backgroundImage: `url(${TrashIcon})`,
      backgroundRepeat: 'no-repeat',
      padding: '0px 9px',
      marginRight: 3,
    },

    '&:disabled': {
      opacity: 0.6,
      cursor: 'auto',
    },
  },
  pinBtn: {
    maskImage: `url(${PushPinInactiveIcon})`,
    maskSize: 20,
    maskRepeat: 'no-repeat',
    minWidth: 20,
    height: 20,
    background: '#FE5000',

    '&.keepPin': {
      maskImage: `url(${PushPinActiveIcon})`,
    },

    '&:hover': {
      background: '#FE50008C',
    },
  },
  close: {
    background: `url(${CloseIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: 24,
    height: 24,
  },
}));
export default useStyles;
