import { makeStyles } from '@material-ui/core';
import { CartIconOrange } from '../../../../assets';

const useStyles = makeStyles(() => ({
  addProduct: {
    padding: '0px 8px 0 3px',
    backgroundColor: '#FEBD01',
    fontSize: 12,
    textTransform: 'initial',
    height: 32,

    '&:hover': {
      backgroundColor: '#FEBD01',
      color: '#000000',
    },
    '&.localFilial': {
      backgroundColor: '#21AD34',
      color: 'white',
    },
    '&.disabled': {
      opacity: 0.5,
    },
  },
  addProductBox: {
    flexDirection: 'row',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  addCartBox: {
    display: 'flex',
    gap: 4,
    alignItems: 'center',
    borderRight: '1px solid',
    paddingRight: 10,
  },
  addIcon: {
    width: 24,
    height: 24,
    maskImage: `url(${CartIconOrange})`,
    background: 'rgba(0, 0, 0, 0.87)',
    maskRepeat: 'no-repeat',
    maskSize: 18,
    maskPosition: 'center',

    '&:after': {
      content: '""',
      borderRight: '1px solid #242424',
      left: 15,
      position: 'relative',
    },

    '&.localFilial': {
      maskImage: `url(${CartIconOrange})`,
      background: '#FFFFFF',

      '&:after': {
        borderColor: '#FFFFFF',
      },
    },
  },
  stockQty: {
    fontSize: 12,
    lineHeight: '14px',
    fontWeight: 600,
  },
  unity: {
    fontSize: 10,
    lineHeight: '10px',
    textTransform: 'lowercase',
  },
  stockBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default useStyles;
