import React, { useEffect, useState } from 'react';
import { AppBar, Box, Toolbar } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import algoliasearch from 'algoliasearch';
import {
  Cart, envVariablesAlgolia, Location, SearchBox,
} from '../../../shared/components/algolia';
import useStyles from './styles';
import { ClientMenu, SellerMenu } from '../../checkout/_components';
import { getClient } from '../../../shared/services/authClient';

const MainAppBarSearchAlgolia = ({
  drawer,
  isCheckout,
  client,
}) => {
  const classes = useStyles();
  const { appBar, toolBarOrange, toolBarWhite } = classes;
  const { appId, indexName, writeApiKey } = envVariablesAlgolia;
  const searchClient = algoliasearch(appId, writeApiKey);
  const indexAlgolia = searchClient.initIndex(indexName);
  const [facets, setFacets] = useState([]);

  useEffect(() => {
    indexAlgolia.getSettings()
      .then((result) => setFacets(result.attributesForFaceting));
  }, []);

  return (
    <>
      <AppBar
        className={`${appBar} search`}
        color="default"
        position="fixed"
      >
        <Toolbar
          disableGutters
          className={`${drawer ? toolBarOrange : toolBarWhite} search`}
          style={{
            paddingLeft: 64,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box className={classes.searchBox}>
            <SellerMenu />
            {!!client && client.logradouro && <Location />}
          </Box>
          <SearchBox
            isCheckout={isCheckout}
            disabled={client ? (!client.confirmAddress && !isCheckout) : false}
            facets={facets}
          />
          <Box className={classes.searchBox}>
            {!isCheckout && (
              <Cart />
            )}
            <ClientMenu />
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

MainAppBarSearchAlgolia.defaultProps = {
  drawer: true,
  isCheckout: false,
  client: null,
};

MainAppBarSearchAlgolia.propTypes = {
  drawer: PropTypes.bool,
  isCheckout: PropTypes.bool,
  client: PropTypes.instanceOf(Object),
};

const mapState = (state) => {
  let { client } = state.authClient;
  client = getClient() || client;

  return ({ client });
};

export default connect(mapState)(MainAppBarSearchAlgolia);
