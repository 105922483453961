import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

import {
  Box,
  Grid,
  Divider,
  Accordion,
  Typography,
  LinearProgress,
  AccordionDetails,
  AccordionSummary,
  Button,
} from '@material-ui/core';

import {
  Alert,
  Pagination
} from '@material-ui/lab';

import { toMoney } from '../../../../shared/utils/parsers';
import CircularLoading from '../../../../shared/components/loading/CircularLoading';

import {
  clearOrderByIdAction,
  getNfeDownloadAction,
  getOrdersAction,
  getOrdersByIdAction,
  newCheckoutAction,
  sendPdfEmailAction
} from './actions';

import {
  DateRange,
  ExpandMore
} from '@material-ui/icons';

import CustomizedSteppers from './_components/stepper/Stepper';
import OrderInfo from './_components/info/OrderInfo';

import useStyles from './styles';
import OrderActions from './_components/actions/OrderActions';
import OrderProducts from './_components/products/OrderProducts';
import OrdersSkeleton from './_components/skeleton/OrderProducts';
import TitleFilter from '../../_components/titleFilter/TitleFilter';
import FilterOrders from './_components/filterOrders/FilterOrders';
import CardPaper from '../../_components/cardPaper/CardPaper';
import ModalErrorInformation from './_components/modalErrorInformation/ModalErrorInformation';

import moment from 'moment';

const OrdersPage = ({
  meta,
  client,
  orders,
  orderById,
  getOrders,
  getOrdersById,
  getNfe,
  clearOrderById,
  sendPdfEmail,
  newCheckout,
  isLoading,
  isLoadingById,
  isLoadingSendEmail,
  isLoadingNewCheckout
}) => {

  const {
    cardGrid,
    accordionFilial,
    accordionFilialDetails,
    accordionFilialSummary,
    accordionTitle,
    accordionDate,
    accordionArrowIcon,
    infoOrder,
    infoOrderinconsistency,
    infoShipping,
    infoShippingTitle,
    infoShippingSubtitle
  } = useStyles();

  const [filterOpened, setfilterOpened] = useState(false);
  const [dataInicio, setDataInicio] = useState(moment(new Date()).subtract(1, 'month').format('DD-MM-YYYY'));
  const [dataFim, setDataFim] = useState(moment(new Date()).format('DD-MM-YYYY'));
  const [expanded, setExpanded] = useState(false);
  const [inconsistencyModal, setInconsistencyModal] = useState(false);

  const loadingModal = isLoading || isLoadingSendEmail || isLoadingNewCheckout;

  const handleTextModal = () => {
    if (isLoadingSendEmail) return 'Enviando email com o PDF do pedido';
    if (isLoadingNewCheckout) return 'Criando novo checkout';

    return 'Carregando Pedidos';
  }

  const handleToggleFilter = () => setfilterOpened(!filterOpened);

  const handleGetOrders = (rpp, page, dataInicioFilter, dataFimFilter) => {
    const dataInicioR = dataInicioFilter || dataInicio;
    const dataFimR = dataFimFilter || dataFim;
    const rppR = rpp || meta.rpp;

    getOrders(
      client.codCliente,
      dataInicioR,
      dataFimR,
      rppR,
      page);
  }
  const handleFilter = (dataInicioFilter, dataFimFilter) => {
    handleGetOrders(meta.rpp, 1, dataInicioFilter, dataFimFilter);
  };


  const handleChange = (codPedido) => (event, isExpanded) => {
    if (isLoadingById) return;

    setExpanded(isExpanded ? codPedido : false)

    if (isExpanded) {
      const codCliente = orders.find((order) => order.codPedido === codPedido).codCliente;

      getOrdersById(codPedido, codCliente);
    } else {
      clearOrderById();
    }
  };

  const handlePaginationChange = (_, page) => handleGetOrders(meta.rpp, page, null, null);

  const handleEmail = () => {
    const { codCliente, entregas } = orderById;
    const codPedido = entregas[0].codPedido;

    sendPdfEmail(codPedido, codCliente);
  }

  const handleGetNfe = (idEntrega) => getNfe(idEntrega);
  const handleGetTransportadora = (url) => window.open(url, '_blank');

  const handleNewCheckout = (codPedido) => {
    if (!codPedido) return;

    newCheckout(codPedido);
  }

  useEffect(() => {
    handleGetOrders(meta.rpp, meta.page);
  }, []);

  const openInconsistencyPanel = () => setInconsistencyModal(
    orderById.motivoCancelamento ||
    orderById.msgPainelDeInconsistencias ||
    false);
  const handleCloseInconsistencyPanel = () => setInconsistencyModal(false);

  return (
    <Grid className={cardGrid}>
      <CircularLoading
        open={loadingModal}
        message={handleTextModal()}
      />

      <ModalErrorInformation isCancelled={orderById?.motivoCancelamento && true} message={inconsistencyModal} onClose={handleCloseInconsistencyPanel}/>

      <Box>
        <TitleFilter
          title="Pedidos do Cliente"
          filterOpened={filterOpened}
          handleToggleFilter={handleToggleFilter}
          onChangeRowsPerPage={({target}) => {handleGetOrders(target.value, 1, null, null)}}
          onChangePage={(_, page) => {handleGetOrders(meta.rpp, page + 1, null, null)}}
          meta={meta}
        />

        <FilterOrders
          open={filterOpened}
          handleFilter={handleFilter}
          onSubmit={() => {}}
          setFilterDataInicio={setDataInicio}
          setFilterDataFim={setDataFim}
          filterDataInicio={dataInicio}
          filterDataFim={dataFim}
          loading={false}
        />

        {orders && orders.length ? orders.map((order) => {
          const {
            codPedido,
            quantidadeProdutos,
            dataPedido,
            total,
            canalOrigem,
            nomeVendedor
          } = order;

          return (
            <Accordion
              expanded={expanded === codPedido}
              onChange={handleChange(codPedido)}
              key={codPedido}
              className={accordionFilial}
            >
              <AccordionSummary
                className={accordionFilialSummary}
                expandIcon={<ExpandMore className={accordionArrowIcon} />}
              >
                <Grid container>
                  <Grid container xs={5}>
                    <Box display="flex" flexDirection="column" height="100%">
                      <Typography className={accordionTitle}>N° Pedido <span>{codPedido}</span> com {quantidadeProdutos} produtos</Typography>
                      <Typography className={`${accordionTitle} ${accordionDate}`}><DateRange fontSize="small"/>{dataPedido}</Typography>
                    </Box>
                  </Grid>
                  <Grid container xs={3}>
                    <Box display="flex" alignItems="center" height="100%">
                      <Divider orientation="vertical" flexItem style={{ marginRight: 8 }} />
                      <Box minWidth="50%">
                        <Typography className={infoOrder}>Canal <span>{canalOrigem}</span></Typography>
                        <Typography className={infoOrder}>{canalOrigem === 'PDV' && (<span>{nomeVendedor}</span>)}</Typography>
                      </Box>
                      <Divider orientation="vertical" flexItem style={{ marginRight: 8, marginLeft: 8 }} />
                    </Box>
                  </Grid>
                  <Grid container xs={4} alignItems="center">
                    <Typography className={accordionTitle}>Valor Total: <span>{toMoney(total)}</span></Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>

              <AccordionDetails className={accordionFilialDetails}>
                <Divider style={{marginBottom: 24}}/>
                {isLoadingById && !orderById ? (
                  <>
                  <LinearProgress />
                  <br/>
                  <OrdersSkeleton />
                  </>
                ) : (
                  <>
                    {orderById && 
                      ((orderById.msgPainelDeInconsistencias && orderById.msgPainelDeInconsistencias.trim() != '') 
                      || orderById.motivoCancelamento)
                      && (
                      <Alert variant="filled" width="100%" severity="error" className={infoOrderinconsistency}>
                        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" width="100%">
                          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                            {orderById.motivoCancelamento ? 'Pedido Cancelado' : 'Painel de Inconsistência'}
                          </Typography>
                          <Button
                            style={{color: 'white', borderColor: 'white'}}
                            variant="outlined"
                            onClick={openInconsistencyPanel}
                          >
                            Ver informações
                          </Button>
                        </Box>
                      </Alert>
                    )}
                    {orderById && orderById.entregas && orderById.entregas.map((entrega, index) => {
                      const {
                        idEntrega,
                        quantidadeItens,
                        itens,
                        dataEntregaPrometida,
                        tracking,
                        objeto
                      } = entrega;

                      return (
                        <>
                          <Box className={infoShipping}>
                            <Box>
                              <Typography className={infoShippingTitle}><span>Entrega {index + 1} </span>(#{idEntrega || 'N/D'} com {quantidadeItens} produtos)</Typography>
                              <Typography className={infoShippingSubtitle}>Vendido e entregue por <span>LojaDoMecanico</span></Typography>
                            </Box>
                            <Box>
                              {dataEntregaPrometida ? 
                                <Typography className={infoShippingTitle}>Chegará até <span>{moment(dataEntregaPrometida).format('DD [de] MMMM')}</span></Typography>
                                : <Typography className={infoShippingTitle}>Sem previsão de entrega</Typography>}
                            </Box>
                          </Box>

                          <OrderProducts products={itens} />
                          <CustomizedSteppers
                            orderDate={dataPedido}
                            tracking={tracking}
                            handleGetNfe={handleGetNfe}
                            handleGetTransportadora={handleGetTransportadora}
                            objeto={objeto}
                          />
                          <Divider style={{ marginTop: 12, marginBottom: 12 }} />
                        </>
                      )
                    })}
                    {orderById && (
                      <>
                        <OrderInfo
                          enderecoEntrega={orderById.enderecoEntrega}
                          pagamentos={orderById.pagamentos}
                        />
                        <OrderActions
                          handleEmail={handleEmail}
                          handleNewCheckout={() => handleNewCheckout(codPedido)}
                        />
                      </>
                    )}
                  </>
                )}
              </AccordionDetails>
            </Accordion>
          )}
        ) : !isLoading && (
          <CardPaper>
            <Box display="flex" justifyContent="center" alignItems="center" height="80px">
              <Typography fontSize={12} fontWeight="bold" color="primary">Nenhum pedido encontrado</Typography>
            </Box>
          </CardPaper>
        )}

        <Box width="100%" display="flex" justifyContent="center" alignItems="center" mt={2}>
          <Pagination color="primary" count={meta.totalpages} page={meta.page} onChange={handlePaginationChange} />
        </Box>
      </Box>
    </Grid>
  );
};

OrdersPage.defaultProps = {};

OrdersPage.propTypes = {};

const mapState = (state) => {
  let { client } = state.authClient;

  const {
    meta,
    orders,
    orderById,
    isLoading,
    isLoadingById,
    isLoadingSendEmail,
    isLoadingNewCheckout
  } = state.main.orders;

  return {
    meta,
    client,
    orders,
    orderById,
    isLoading,
    isLoadingById,
    isLoadingSendEmail,
    isLoadingNewCheckout
  };
};

export default connect(mapState, {
  getOrders: getOrdersAction,
  getOrdersById: getOrdersByIdAction,
  sendPdfEmail: sendPdfEmailAction,
  getNfe: getNfeDownloadAction,
  newCheckout: newCheckoutAction,
  clearOrderById: clearOrderByIdAction
})(OrdersPage);

