import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
  Box,
  Step,
  Button,
  Stepper,
  StepLabel,
  Typography,
}from '@material-ui/core';

import useStyles, {
  ColorlibConnector,
  useColorlibStepIconStyles
} from './styles';

import {
  AccessAlarm,
  AssignmentOutlined,
  CheckOutlined,
  LocalShippingOutlined,
  ShoppingCartOutlined
} from '@material-ui/icons';

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <ShoppingCartOutlined fontSize='small'/>,
    2: <AccessAlarm fontSize='small'/>,
    3: <AssignmentOutlined fontSize='small' />,
    4: <LocalShippingOutlined fontSize='small'/>,
    5: <CheckOutlined fontSize='small'/>,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {active ? icons[String(props.icon)] : <></>}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

function getSteps() {
  return [
    'Pedido realizado',
    'Pagamento aprovado',
    'Nota fiscal emitida',
    'Entregue à transportadora',
    'Pedido entregue'
  ];
}

const Steppers = ({
  orderDate,
  tracking,
  objeto,
  handleGetNfe,
  handleGetTransportadora,
}) => {
  const classes = useStyles();
  const {
    stepRoot,
    labelStep,
    labelStepDate,
    labelStepActive,
    labelStepDisabled,
    buttonActions,
    buttonActionsBlue
  } = classes;

  const steps = tracking;
  const computedActiveStep = tracking.reduce((acc, curr, index) => (curr.data ? index : acc), 0);

  const stepIsDisabled = (stepIndex) => stepIndex > computedActiveStep;

  const showButtonNF = (stepLabel, index) => {
    return (
      index <= computedActiveStep &&
      stepLabel.toLowerCase().includes("nota fiscal") &&
      tracking.idEntrega
    );
  };

  const showButtonShipping = (stepLabel, index) => {
    return (
      index <= computedActiveStep &&
      stepLabel.toLowerCase().includes("transporte") &&
      objeto.linkTransportadora
    );
  };

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel activeStep={computedActiveStep} connector={<ColorlibConnector />}>
        {steps.map((step, index) => {
          const labelText = step.descricaoTracking;

          const dateText = (step.data || index === 0 ? orderDate : '--/--/----')
          return (
            <Step key={index} className={stepRoot}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <Box 
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  flexGrow={1}
                  height="100%"
                >
                  <Box>
                    <Typography
                      className={clsx(
                        labelStep,
                        computedActiveStep === index && labelStepActive,
                        stepIsDisabled(index) && labelStepDisabled
                      )}
                    >
                      {labelText}
                    </Typography>
                    <Typography
                      className={clsx(
                        labelStepDate,
                        stepIsDisabled(index) && labelStepDisabled
                      )}
                    >
                      {dateText}
                    </Typography>
                  </Box>
                </Box>
              </StepLabel>

              <Box>
                {showButtonNF(labelText, index) && (
                  <Button
                    fullWidth
                    className={buttonActions}
                    disableElevation
                    onClick={() => handleGetNfe(tracking.idEntrega)}
                    variant="outlined"
                  >
                    Ver NF
                  </Button>
                )}

                {showButtonShipping(labelText, index) && (
                  <Button
                    fullWidth
                    className={`${buttonActions} ${buttonActionsBlue}`}
                    disableElevation
                    onClick={() => handleGetTransportadora(objeto.linkTransportadora)}
                    variant="outlined"
                  >
                    Ver rastreio
                  </Button>
                )}
              </Box>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
};

Steppers.propTypes = {
  tracking: PropTypes.array,
};

export default Steppers;
