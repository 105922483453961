import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints, transitions }) => ({
  wrapper: {
    backgroundColor: '#F3F4F6',
    maxHeight: 0,
    maxWidth: '1200px',
    boxShadow: 'none',
    borderRadius: '16px',
    marginBottom: '14px',
    backgroundColor: '#FFFFFF',
    overflow: 'hidden',
    transition: transitions.create('max-height', {
      duration: transitions.duration.standard,
      easing: transitions.easing.easeInOut,
    }),
  },
  wrapperOpened: {
    maxHeight: 180,
    [breakpoints.down('xs')]: {
      maxHeight: 520,
    },
  },
  searchButton: {
    float: 'right',
  },
  inputFakeDate: {
    display: 'flex',
    flexDirection: 'row',
    color: '#242424',
    height: '48px',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '20px',
    borderColor: '#CFCFD4',
    borderRadius: '8px',
    justifyContent: 'space-between',
    borderColor: 'rgba(0, 0, 0, 0.26)',
    borderStyle: 'solid',
    borderWidth: 1,
    color: '#6C757D',
    height: 48,
    fontSize: 14,
    paddingLeft: 5,
    cursor: 'pointer',
    alignItems: 'center',
    '&:active': {
      borderColor: '#FF5225',
    },
    [breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  orderStatus: {
		width: '12px',
		height: '12px',
		fontSize: '7px',
		marginRight: '4px',
		borderRadius: '20px',
		'& .MuiBox-root': {
		}
	},
  buttonCleanFilter: {
    '& .MuiButton-label': {
      display: 'flex',
      gap: '5px',
    },

    '& svg path': {
      fill: '#F4282D'
    },

    '& .MuiTypography-body1': {
      display: 'flex',
      alignItems: 'center',
      color: '#F4282D',
      fontSize: '12px',
      fontWeight: '600',
      textTransform: 'capitalize',
      height: '32px',
    }
  },
  actionsFilter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 24,
  },
  labelField: {
    fontSize: 14,
    fontWeight: 600,
    color: '#7B7F82',
    marginBottom: 6,
  },
}));

export default useStyles;
