import React from 'react';
import { Box, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './styles';

const AddButton = ({
  localFilial,
  qty,
  disabled,
  onClick,
  className,
  listCard,
}) => {
  const classes = useStyles();
  const isFilialClass = localFilial ? 'localFilial' : '';

  const {
    addCartBox,
    addIcon,
    addProduct,
    addProductBox,
    stockBox,
    stockQty,
    unity,
  } = classes;

  return (
    <Button
      disableElevation
      className={`${addProduct} ${isFilialClass} ${className} ${!!disabled && 'disabled'}`}
      style={{ width: listCard ? 240 : 89 }}
      disabled={!!disabled}
      onClick={onClick}
    >
      <Box className={addProductBox}>
        <Box className={addCartBox}>
          <div className={`${addIcon} ${isFilialClass}`} />
          {listCard && (<span>Adicionar ao Carrinho</span>)}
        </Box>

        <Box className={`${stockBox} ${isFilialClass}`}>
          <span className={stockQty}>{qty}</span>
          <span className={unity}>unid.</span>
        </Box>
      </Box>
    </Button>
  );
};

AddButton.defaultProps = {
  localFilial: false,
  disabled: false,
  qty: 0,
  className: '',
  listCard: false,
};

AddButton.propTypes = {
  localFilial: PropTypes.bool,
  disabled: PropTypes.bool,
  qty: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  listCard: PropTypes.bool,
};

export default AddButton;
