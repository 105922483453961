import React, { useState, useEffect } from 'react';

import {
  Box,
  Grid,
  Popover,
  Button,
  Typography,
  IconButton,
} from '@material-ui/core';

import {
  reset,
  reduxForm
} from 'redux-form';

import { connect, useDispatch } from 'react-redux';
import { pt } from 'date-fns/locale';

import moment from 'moment';
import * as PropTypes from 'prop-types';

import { DateRange } from 'react-date-range';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import SearchIcon from '@material-ui/icons/Search';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { ReactComponent as TrashIcon } from '../../../../../../assets/algolia/trash-icon.svg';

import {
  InputFieldForm
} from '../../../../../../shared/fields';

import useStyles from './style';

const FilterOrders = ({
  open,
  loading,
  onSubmit,
  handleFilter,
  handleSubmit,
  setFilterDataFim,
  setFilterDataInicio,
}) => {
  const classes = useStyles();

  const {
    wrapper,
    wrapperOpened,
    filter,
    inputFakeDate,
    searchButton,
    actionsFilter,
    buttonCleanFilter,
    labelField
  } = classes;
  const dispatch = useDispatch();

  const [dateOpen, setDateOpen] = useState(null);
  const [dateInicio, setDateInicio] = useState('');
  const [dateFim, setDateFim] = useState('');

  const d = new Date();
  d.setDate(d.getDate() - 30);

  const [date, setDate] = useState([
    {
      startDate: d,
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const handleRequest = (dataInicio, dataFim) => {
    const dataFilterInicio = moment(dataInicio || date[0].startDate).format('DD-MM-YYYY');
    const dataFilterFim = moment(dataFim || date[0].endDate).format('DD-MM-YYYY');

    handleFilter(dataFilterInicio, dataFilterFim);
  };

  useEffect(() => {
    setFilterDataInicio(moment(date[0].startDate).format('DD-MM-YYYY'));
    setFilterDataFim(moment(date[0].endDate).format('DD-MM-YYYY'));
    setDateInicio(moment(date[0].startDate).format('DD-MM-YYYY'));
    setDateFim(moment(date[0].endDate).format('DD-MM-YYYY'));
  }, [date]);

  const handleClearRequest = () => {
    const startDate = new Date().setDate(d.getDate() - 30);
    const endDate = new Date();

    dispatch(reset('orders/filter'));
    setFilterDataInicio(moment(new Date()).subtract(1, 'month').format('DD-MM-YYYY'));
    setFilterDataFim(moment(new Date()).format('DD-MM-YYYY'));
    setDateInicio(moment(new Date()).subtract(1, 'month').format('DD-MM-YYYY'));
    setDateFim(moment(new Date()).format('DD-MM-YYYY'));
    handleRequest(startDate, endDate);
  }

  return (
    <div
      className={`${wrapper} ${open ? wrapperOpened : null}`}
    >
      <form onSubmit={handleSubmit(() => onSubmit({ page: 1 }))}>
        <Box p={2}>
          <Grid className={filter} container spacing={2}>
            <Grid item xs={3}>
              <InputFieldForm
                name="codPedido"
                minLength={6}
                maxLength={100}
                label="N° Pedido"
              />
            </Grid>

            <Grid item xs={5}>
              <div>
                <label className={labelField}>Data</label>
                <div
                  style={{ marginTop: 6}}
                  className={inputFakeDate}
                  onClick={(e) => setDateOpen(e.currentTarget)}
                >
                  <div>
                    <span>
                      {moment(dateInicio, 'DD-MM-YYYY').format('DD/MM/YYYY')}
                    </span>
                    <span> - </span>
                    <span>{moment(dateFim, 'DD-MM-YYYY').format('DD/MM/YYYY')}</span>
                  </div>
                  <ArrowDropDownIcon style={{ marginLeft: 30 }} />
                </div>
                <Popover
                  open={Boolean(dateOpen)}
                  anchorEl={dateOpen}
                  onClose={() => setDateOpen(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <DateRange
                    date={new Date()}
                    name="date"
                    maxDate={new Date()}
                    onChange={(item) => setDate([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={date}
                    rangeColors={['#FF5225']}
                    showMonthAndYearPickers={false}
                    locale={pt}
                  />
                </Popover>
              </div>
            </Grid>

            <Grid className={actionsFilter} item xs={4} alignItems="center">
              <IconButton
                type="submit"
                className={searchButton}
                onClick={() => handleRequest()}
                disabled={loading}
              >
                <SearchIcon />
              </IconButton>
              <Button
                fullWidth
                onClick={() => handleClearRequest()}
                className={buttonCleanFilter}
                disabled={loading}
              >
                <TrashIcon/>
                <Typography>Limpar Filtro</Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </div>
  );
};

FilterOrders.defaultProps = {
  loading: false,
};

FilterOrders.propTypes = {
  page: PropTypes.string,
  open: PropTypes.bool.isRequired,
  isCheckout: PropTypes.bool,
  loading: PropTypes.bool,
  handleFilter: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFilterDataInicio: PropTypes.func,
  setFilterDataFim: PropTypes.func,
};

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, {})
(reduxForm({
  form: 'orders/filter',
  destroyOnUnmount: false,
  initialValues: {
    dataInicio: moment(new Date()).subtract(1, 'month').format('DD-MM-YYYY'),
    dataFim: moment(new Date()).format('DD-MM-YYYY'),
  },
})(FilterOrders));
