import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  cardGrid: {
    maxWidth: 1200,
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
  },

  accordionFilial: {
    '&&': {
      '&.MuiAccordion-root:before': {
        backgroundColor: 'transparent'
      },
      '&.MuiAccordion-root.Mui-expanded': {
        margin: 0,
        marginBottom: 14,
      },
      border: '1px solid #CFCFD4',
      padding: '12px 24px',
      maxWidth: '1200px',
      borderRadius: '16px',
      marginBottom: 14,
      borderRadius: 16,
      backgroundColor: '#FFFFFF',
      boxShadow: 'none',
    }
  },
  accordionFilialDetails: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  accordionArrowIcon: {
    '&& ': {
      padding: 0,
      color: '#FF5225',
      height: 28,
      width: 28,
    },
  },
  accordionFilialSummary: {
    '&&': {
      padding: 0,
      borderRadius: 8,
      minHeight: 70,

      '& .MuiAccordionSummary-content.Mui-expanded': {
        minHeight: 70,
        margin: 0
      },
      '& .MuiAccordionSummary-expandIcon': {
        padding: 0
      }
    }
  },
  accordionTitle: {
    fontSize: 12,
    color: '#7B7F82',

    '& span': {
      fontSize: 16,
      color: '#242424',
      fontWeight: '600',
    },
  },
  accordionDate: {
    display: 'flex',
    alignItems: 'center',
    color: '#7B7F82',
    '& svg': {
      height: 14,
      width: 14,
      marginRight: 4,
      margin: 0,
      padding: 0
    }
  },
  infoOrder: {
    fontSize: 12,
    color: '#7B7F82',

    '& span': {
      fontSize: 12,
      color: '#242424',
      fontWeight: '600',
    }
  },
  infoShipping: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  infoShippingTitle:{
    fontSize: 14,
    color: '#7B7F82',

    '& span': {
      color: '#FA4616',
      fontWeight: '600',
    }
  },
  infoShippingSubtitle:{
    lineHeight: 1,
    fontSize: 12,
    color: '#242424',

    '& span': {
      color: '#FA4616',
      fontWeight: '600',
    }
  },
  infoOrderinconsistency: {
    marginBottom: 14,
    width: '100%',
    alignItems: 'center',
    '& .MuiAlert-message': {
      width: '100%',
    }
  }
}));

export default useStyles;
