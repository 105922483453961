import React from 'react';
import * as PropTypes from 'prop-types';

import {
  Box,
  IconButton,
  TablePagination,
  Typography
} from '@material-ui/core';

import { KeyboardArrowDown } from '@material-ui/icons';

import useStyles from './styles';

const TitleFilter = ({
  title,
  handleToggleFilter,
  filterOpened,
  onChangePage,
  onChangeRowsPerPage,
  meta,
}) => {
  const {
    infoContent,
    titleTypography,
    icon,
    iconFilterClosed,
    iconFilterOpened
  } = useStyles();

  return (
    <div className={infoContent}>
      <Typography className={titleTypography}>{title}</Typography>
      <Box display="flex" alignItems="center">
        <TablePagination
          SelectProps={{
            IconComponent: KeyboardArrowDown,
          }}
          rowsPerPageOptions={[10, 20, 40]}
          component="div"
          count={meta.size || 0}
          rowsPerPage={meta.rpp}
          page={meta.page - 1}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          labelRowsPerPage="Registros:"
          />
        <IconButton onClick={handleToggleFilter}>
          <span className={`${icon} ${filterOpened ? iconFilterOpened : iconFilterClosed}`} />
        </IconButton>
      </Box>
    </div>
  );
};

TitleFilter.defaultProps = {
  onChangePage: () => {},
  onChangeRowsPerPage: () => {},
  meta: {
    page: 1,
    rpp: 10,
    size: 0,
  },
  showSelect: false,
};
TitleFilter.propTypes = {
  title: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  meta: PropTypes.object,

};

export default TitleFilter;
