/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Checkbox, Grid, ListItem, ListItemAvatar, ListItemSecondaryAction,
  ListItemText, Tooltip, Typography, Zoom,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { green } from '@material-ui/core/colors';
import { toCEP, toMoney, toCPF, toCNPJ, toPhone } from '../../../../shared/utils/parsers';
import useStyles from './styles';
import { CalendarToday } from '@material-ui/icons';
import moment from 'moment';

const ConfirmFreight = ({
  address,
  freight,
  customer,
  forceFreight,
  confirmAdress,
  checkedPickup,
  confirmFreight,
  checkedSameday,
  checkedNitrous,
  checkedSchedule,
  freighConfirmed,
  handleConfirmAdress,
  enablePickupTransfer,
  estimatedDeliveryDays,
  dataEntregaAgendadaEscolhida,
  handleNewDate
}) => {

  const {
    boxContainer,
    boxContainerSelected,
    typographyFreightPrimary,
    typographyFreightSecondary,
    customInfo,
  } = useStyles();

  const getFreightPrimaryText = () => {
    let text = '';

    if (forceFreight) {
      text = 'Forçar CD de saída';
    } else if (enablePickupTransfer && checkedPickup) {
      text = 'Retira por Transferência';
    } else if (checkedPickup) {
      text = 'Retira CD';
    } else if (checkedSameday) {
      text = 'Mesmo Dia';
    } else if (checkedNitrous) {
      text = 'Entrega Expressa';
    } else if (checkedSchedule) {
      text = 'Entrega Agendada';
    } else {
      text = 'Transportadora';
    }

    return `${text} ${toMoney(freight)}`;
  };

  const getFreightSecondaryText = () => {
    if (checkedSchedule) {
      return (
        <Button sx={{p:0}} onClick={handleNewDate} color="primary" variant="text" disabled={freighConfirmed}>
          <CalendarToday style={{fontSize: "1rem", marginRight: 5}} />
          {dataEntregaAgendadaEscolhida ? (
            `${moment(dataEntregaAgendadaEscolhida, "DD/MM/YYYY").format("DD")} de ${moment(dataEntregaAgendadaEscolhida, "DD/MM/YYYY").format('MMMM')}`
          ) : (
            'Selecione uma Data'
          )}
        </Button>
      )
    }

    if (estimatedDeliveryDays) {
      return `Previsão de Entrega em até ${estimatedDeliveryDays} dias úteis`
    }

    return 'Sem previsão de entrega ainda';
  }

  return (
    <Grid container style={{fontWeight:'bold'}} spacing={2}>
      <Grid item xs={12} sm={6}>
        <Box className={`${boxContainer} ${confirmAdress && boxContainerSelected}`}>
          <ListItem dense>
            <Zoom in={!confirmAdress}>
              <ListItemAvatar>
                <Tooltip title="Confirme o endereço de entrega">
                  <Checkbox
                    checked={confirmAdress}
                    color="primary"
                    onChange={handleConfirmAdress}
                  />
                </Tooltip>
              </ListItemAvatar>
            </Zoom>
            <Zoom in={confirmAdress}>
              <ListItemSecondaryAction style={{top: 0, right: 43, position:'inherit'}}>
                <Box color={green[500]}>
                  <CheckIcon color="inherit" />
                </Box>
              </ListItemSecondaryAction>
            </Zoom>

            <ListItemText sx={{ wordBreak: "break-word" }}
              primary={`${customer.name.toUpperCase() + ((customer.typePerson || '') === 'F' && customer.nomeSocial != null ? ` (${customer.nomeSocial.toUpperCase() })` : '')}
              ${(customer.typePerson || '') === 'F' ? " CPF:" : " CNPJ:"}${(customer.typePerson || '') === 'F' ? toCPF(customer.document || '') : toCNPJ(customer.document || '')}`}
              secondary={
                <div>
                  {address.street ? `${address.street}, ${address.number}` : ''}
                  <br/>
                  {address.zipCode ? `${address.city}/${address.state} - CEP ${toCEP(address.zipCode)}` : ''}
                  <br/>
                  {toPhone(customer.phone)}
                  <br/>
                  {customer.email.length ? customer.email : 'Não cadastrado'}
                  <br/>
                  {customer.produtorRural && (
                    <>
                      <Tooltip title={`O cliente é produtor rural. Inscrição Estadual: ${customer.rgIe}`} >
                        <span className={`${customInfo}`}>{<span className='produtor'/>}Produtor Rural</span>
                      </Tooltip>
                    </>
                  )}
                </div>
              }
              style={{marginRight:10}}
              primaryTypographyProps={{
                style: {
                  fontWeight: 'bold',
                  fontSize: '14px',
                },
              }}
              secondaryTypographyProps={{
                style: {
                  fontWeight: '500',
                  fontSize: '14px',
                  wordWrap: 'break-word',
                  whiteSpace: "normal",
                },
              }}
            />
          </ListItem>
        </Box>
      </Grid>

      <Grid item xs={12} sm={6} >
        <Box className={`${boxContainer} ${freighConfirmed && boxContainerSelected}`}>
          <ListItem dense>
            <Zoom in={!freighConfirmed}>
              <ListItemAvatar>
                <Tooltip title="Confirme a forma de entrega">
                  <Checkbox
                    disabled={checkedSchedule && !dataEntregaAgendadaEscolhida}
                    checked={freighConfirmed}
                    color="primary"
                    onChange={confirmFreight}
                  />
                </Tooltip>
              </ListItemAvatar>
            </Zoom>
            <Zoom in={freighConfirmed}>
              <ListItemSecondaryAction   style={{top: 0, right: 43, position:'inherit'}}>
                <Box color={green[500]}>
                  <CheckIcon color="inherit" />
                </Box>
              </ListItemSecondaryAction>
            </Zoom>
            <Box display="flex" flexDirection="column">
              <Typography className={typographyFreightPrimary}>
                {getFreightPrimaryText()}
              </Typography>
              <Typography className={typographyFreightSecondary}>
                {getFreightSecondaryText()}
              </Typography>
            </Box>
          </ListItem>
        </Box>
      </Grid>

    </Grid>
  );
}

ConfirmFreight.defaultProps = {
  estimatedDeliveryDays: null,
};

ConfirmFreight.propTypes = {
  address: PropTypes.instanceOf(Object).isRequired,
  customer: PropTypes.instanceOf(Object).isRequired,
  checkedPickup: PropTypes.bool.isRequired,
  enablePickupTransfer: PropTypes.bool.isRequired,
  forceFreight: PropTypes.bool,
  checkedSameday: PropTypes.bool.isRequired,
  freight: PropTypes.number.isRequired,
  confirmFreight: PropTypes.func.isRequired,
  freighConfirmed: PropTypes.bool.isRequired,
  dataEntregaAgendadaEscolhida: PropTypes.string,
  handleNewDate: PropTypes.func,
  estimatedDeliveryDays: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

export default ConfirmFreight;
