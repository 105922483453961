import { makeStyles } from '@material-ui/core';
import { AIIcon, ExitIcon, LikeIcon } from '../../../assets';

const useStyles = makeStyles(({ breakpoints }) => ({
  chatAIBtn: {
    position: 'absolute',
    bottom: 0,
    right: 24,
    backgroundColor: '#2296F3',
    color: '#FFFFFF',
    boxShadow: 'none',
    fontFamily: ['Poppins', 'sans'],
    fontSize: 18,
    lineHeight: '30px',
    height: 62,
    width: 274,
    borderRadius: 150,

    '&.noFooter': {
      bottom: 21,

      [breakpoints.down('sm')]: {
        right: 'auto',
        bottom: 200,
      },
    },

    '& strong': {
      marginRight: 8,
    },

    '&:hover': {
      backgroundColor: '#165A92',
    },

    [breakpoints.down('sm')]: {
      bottom: 280,
      right: 'auto',
    },
  },
  chatAIGrid: {
    height: 90,
    '&.open': {
      '&.noFooter': {
        [breakpoints.down('sm')]: {
          top: 0,
        },
      },

      [breakpoints.down('sm')]: {
        height: 610,
        top: 0,
      },
    },

    '&.noFooter': {
      [breakpoints.down('sm')]: {
        top: '164px',
      },
    },

    [breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      right: 'auto',
      width: '100%',
    },
  },
  chatAIMain: {
    width: 1100,
    bottom: 28,
    height: 457,
    position: 'absolute',
    fontSize: 20,
    backgroundColor: '#0F1012CC',
    right: 0,
    display: 'flex',
    justifyContent: 'end',

    '&.noFooter': {
      bottom: '0!important',
      height: 557,

      [breakpoints.down('sm')]: {
        height: 610,
        position: 'absolute',
      },
    },

    [breakpoints.down('sm')]: {
      position: 'absolute',
      bottom: 0,
      width: '100vw',
      height: 610,
    },
  },
  chatAIBox: {
    '&': {
      position: 'relative'
    }
  },
  chatAIBoxMain: {
    backgroundColor: '#F6F6F7',
    width: '75%',
    position: 'relative',

    [breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  expandBtn: {
    position: 'absolute',
    top: -19,
    right: '50%',
    width: '37px !important',
    height: '20px !important',
    borderBottom: 0,
    borderLeft: '1px solid #CFCFD4',
    backgroundColor: '#FFFFFF !important',
    borderRight: '1px solid #CFCFD4',
    borderTop: '1px solid #CFCFD4',
    cursor: 'pointer',
    borderRadius: '8px 8px 0 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'end',

    '& span': {
      borderTop: '3px solid #2296F3',
      borderLeft: '3px solid #2296F3',
      display: 'block',
      width: 11,
      height: 11,
      transform: 'rotate(45deg)',
      marginBottom: -3,

      '&.minimize': {
        transform: 'rotate(225deg)',
        borderColor: '#CFCFD4',
        marginBottom: 0,
      },
    },

    '&:disabled': {
      cursor: 'auto',

      '& span': {
        borderColor: 'rgb(149 149 149 / 87%)',
      },
    },

    [breakpoints.down('sm')]: {
      right: '45%',
    },
  },
  chatAIHeader: {
    background: '#FFFFFF',
    border: '1px solid #CFCFD4',
    borderLeft: 0,
    borderRight: 0,
    boxShadow: '0px 2px 10px 0px #00000014',
    height: 62,
    display: 'flex',
    position: 'absolute',
    width: '100%',
    justifyContent: 'space-between',
    padding: '14px 30px',
    alignItems: 'center',
    color: '#2296F3',
    fontWeight: 600,
    textTransform: 'uppercase',

    '& strong': {
      marginRight: 8,
    },
  },
  chatAIContent: {
    height: 261,
    marginTop: 62,
    marginBottom: 101,
    padding: 20,
    overflowY: 'auto',

    '& h5': {
      fontSize: 18,
      lineHeight: '24px',
      fontWeight: 600,
    },

    '& $chatAIAnswerBox:last-child': {
      border: '0 !important',
    },

    '&::-webkit-scrollbar': {
      width: 6,
      background: '#E3E3E7',
      borderLeft: '1px solid #CFCFD4',
    },

    '&::-webkit-scrollbar-track': {
      borderRadius: 10,
    },

    '&::-webkit-scrollbar-thumb': {
      background: '#909599',
      borderRadius: 10,

      '&:hover': {
        background: '#909599',
      },
    },

    [breakpoints.down('sm')]: {
      height: 448,
    },
  },
  chatAIAnswerBox: {
    padding: '16px 0',
    borderBottom: '1px solid #CFCFD4',
  },
  chatAIAnswerCard: {
    boxShadow: 'none',
    background: 'none',
    display: 'flex',
    flexDirection: 'column',
    gap: 8,

    '& h6': {
      fontSize: 14,
      lineHeight: '18px',
      fontWeight: 600,
      textTransform: 'uppercase',
    },

    '& p': {
      fontSize: 12,
      lineHeight: '15px',
    },
  },
  chatAIFooter: {
    width: '100%',
    borderTop: '1px solid #CFCFD4',
    display: 'flex',
    position: 'absolute',
    background: '#FFFFFF',
    borderLeft: 0,
    borderRight: 0,
    bottom: 0,
    boxShadow: '0px -2px 10px 0px #00000014',
    justifyContent: 'space-between',
    padding: 20,

    '& form': {
      width: '100%',
    },

    '& button': {
      backgroundColor: '#2296F3',
      width: 140,
      height: 40,
      color: '#FFFFFF',
      textTransform: 'capitalize',
      fontSize: 12,
      lineHeight: '14px',
      fontWeight: 600,

      '&:hover': {
        backgroundColor: '#165A92',
      },

      '&:disabled': {
        backgroundColor: 'rgb(149 149 149 / 87%)',
        color: '#FFFFFF',
        cursor: 'auto',
      },
    },
  },
  closeChatBtn: {
    background: `url(${ExitIcon})`,
    width: 20,
    height: 20,
    border: 0,
    cursor: 'pointer',
  },
  formGrid: {
    display: 'flex',
    alignItems: 'center',

    '& .MuiOutlinedInput-root': {
      height: 40,
      background: '#F6F6F7',
      borderRadius: 8,
      marginRight: 20,

      '& input': {
        fontSize: 12,
        lineHeight: '16px',
      },

      [breakpoints.down('sm')]: {
        margin: 0,
      },
    },
  },
  footerBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    width: '100%',
  },
  answerAICard: {
    boxShadow: 'none',
    border: '1px solid #2296F3',
    borderRadius: 16,
    backgroundColor: '#EAF5FD',
    padding: 20,
    paddingRight: 0,

    '& p': {
      fontSize: 12,
      lineHeight: '17px',
      fontWeight: 500,
    },
  },
  answerAIBox: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    maxHeight: 155,
    paddingRight: 20,
    gap: 12,

    '& h6': {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '18px',
      textTransform: 'uppercase',
    },

    '&::-webkit-scrollbar': {
      width: 6,
    },

    '&::-webkit-scrollbar-track': {
      borderRadius: 10,
    },

    '&::-webkit-scrollbar-thumb': {
      background: '#2296F3',
      borderRadius: 10,

      '&:hover': {
        background: '#165A92',
      },
    },
  },
  aiIcon: {
    background: `url(${AIIcon})`,
    display: 'block',
    width: 16,
    height: 16,
    marginRight: 8,
  },
  aiIconDescription: {
    fontSize: 10,
    lineHeight: '15px',
    color: '#7B7F82',
    display: 'flex',
    gap: 6,
    fontWeight: 500,

    '&:before': {
      content: '""',
      '-webkit-mask-image': `url(${AIIcon})`,
      maskImage: `url(${AIIcon})`,
      backgroundColor: '#2296F3',
      display: 'block',
      width: 12,
      height: 12,
      maskSize: 12,
    },
  },
  likeIcon: {
    backgroundColor: '#FFFFFF !important',
    border: '1px solid #CFCFD4 !important',
    width: '32px !important',
    borderRadius: '100% !important',
    height: '32px !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',

    '&:before': {
      content: '""',
      '-webkit-mask-image': `url(${LikeIcon}) !important`,
      maskImage: `url(${LikeIcon}) !important`,
      backgroundColor: '#7B7F82',
      width: 14,
      height: 14,
    },

    '&:hover': {
      backgroundColor: '#E8E8E8 !important',
    },

    '&.selected': {
      backgroundColor: '#2296F3 !important',
      border: '0 !important',
      '&:before': {
        backgroundColor: '#FFFFFF',
      },
    },
  },
  dontLikeIcon: {
    backgroundColor: '#FFFFFF !important',
    border: '1px solid #CFCFD4 !important',
    width: '32px !important',
    borderRadius: '100% !important',
    height: '32px !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'rotateY(180deg)',
    cursor: 'pointer',

    '&:before': {
      content: '""',
      '-webkit-mask-image': `url(${LikeIcon}) !important`,
      maskImage: `url(${LikeIcon}) !important`,
      backgroundColor: '#7B7F82',
      transform: 'rotate(180deg)',
      width: 14,
      height: 14,
    },

    '&:hover': {
      backgroundColor: '#E8E8E8 !important',
    },

    '&.selected': {
      backgroundColor: '#2296F3 !important',
      border: '0 !important',
      '&:before': {
        backgroundColor: '#FFFFFF',
      },
    },
  },
  aiDescriptionBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: 10,
    },
  },
  likeBox: {
    display: 'flex',
    gap: 12,
  },
  chatAIContentBox: {
    position: 'relative',
    zIndex: 0,
  },
  formBox: {
    display: 'flex',

    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: 16,
    },
  },
}));

export default useStyles;
