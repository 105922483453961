import React, { useEffect, useState } from 'react';
import { useSortBy } from 'react-instantsearch';
import { Box, Input } from '@material-ui/core';
import useStyles from './styles';
import { handleSortBy } from '../../../utils/algolia';
import { SelectStyled as Select } from '../../../../main/checkout/_components/algolia/sellerMenu/styles';

const SortBy = (props) => {
  const classes = useStyles();
  const {
    selectSortByMenu,
    sortByBox,
    sortByBoxMain,
    sortByInput,
    sortByItem,
    sortBySelect,
  } = classes;

  const sortByOptions = handleSortBy();
  const [sortBySelected, setSortBySelected] = useState('');

  const { options, refine } = useSortBy({
    ...props,
    items: sortByOptions,
  });

  const handleSortBySelected = (selected) => {
    refine(selected);
    setSortBySelected(selected);
  };

  useEffect(() => {
    setSortBySelected(options[0].value || '');
  }, []);

  useEffect(() => {
    if (sortBySelected) refine(sortBySelected);
  }, [sortBySelected]);

  return (
    <Box className={sortByBoxMain}>
      <Box className={sortByBox}>
        <span>Ordenar por:</span>
        <Select
          native
          classes={{ root: sortBySelect }}
          value={sortBySelected}
          onChange={(e) => handleSortBySelected(e.target.value)}
          input={<Input classes={{ root: sortByInput }} disableUnderline />}
          MenuProps={{ classes: { paper: selectSortByMenu } }}
        >
          {
            options.map(({ label, value }) => (
              <option
                className={sortByItem}
                value={value}
                name={label}
                key={label}
              >
                {label}
              </option>
            ))
          }
        </Select>
      </Box>
    </Box>
  );
};

export default SortBy;
