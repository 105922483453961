import React from 'react';

import {
  Box,
  Button
} from '@material-ui/core';

import {
  EmailOutlined,
  ShoppingCartOutlined
} from '@material-ui/icons';
import useStyles from './styles';

export default function OrderActions({ handleEmail, handleNewCheckout}) {
  const classes = useStyles();
  const {
    button
  } = classes;

  return (
    <Box display="flex" justifyContent="center" gap={12} mt={2}>
      <Button
        disableElevation
        className={button}
        variant="outlined"
        color="primary"
        onClick={handleEmail}
      >
        <EmailOutlined fontSize="small" />
        Enviar por E-Mail
      </Button>
      <Button
        disableElevation
        className={button}
        variant="contained"
        color="primary"
        onClick={handleNewCheckout}
      >
        <ShoppingCartOutlined fontSize="small" />
        Comprar Novamente
      </Button>
    </Box>
  );
}
