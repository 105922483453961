import { makeStyles } from '@material-ui/core';
import {
  CopyIcon,
  PplIcon,
  ZoomIcon,
} from '../../../../../assets/algolia';

const useStyles = makeStyles(({ breakpoints }) => ({
  card: {
    maxWidth: 209,
    minWidth: 209,
    minHeight: 444,
    maxHeight: 444,
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
    border: '1px solid #E3E3E7',
    borderRadius: '0 0 8px 8px',
    padding: 8,
    borderTopWidth: 5,
    borderTopColor: '#FEBD01',

    '&.disabled': {
      borderTopColor: '#7B7F82',
    },

    '&.localFilial': {
      borderTopColor: '#21AD34',
    },
  },
  margin: {
    fontSize: 12,
    color: '#7B7F82',
    fontWeight: 500,
  },
  productId: {
    fontSize: 12,
    fontWeight: 600,
    color: '#242424',
    paddingLeft: 3,
    marginRight: 3,
  },
  stockTotalStyled: {
    border: '1px solid #CFCFD4',
    color: '#7B7F82',
    fontWeight: 500,
    fontSize: 10,
    lineHeight: '14px',
    borderRadius: 4,
    padding: '4px 8px 4px 8px',
    textOverflow: 'ellipsis',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 1,
    display: '-webkit-box',
    overflow: 'hidden',
  },
  productGrid: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  copyIcon: {
    maskImage: `url(${CopyIcon})`,
    maskRepeat: 'no-repeat',
    maskSize: '12px 13px',
    backgroundColor: '#FE5000',
    width: 12,
    height: 12,
  },
  valuesGrid: {
    display: 'flex',
    alignItems: 'baseline',
    flexWrap: 'wrap',
  },
  productImage: {
    width: 145,
    height: 145,
    marginTop: 8,

    '&.disabled': {
      filter: 'grayscale(1)',
      opacity: 0.5,
    },
  },
  gridCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  relative: {
    position: 'relative',
  },
  imageHover: {
    width: 207,
    height: 145,
    position: 'absolute',
    marginTop: 8,
    display: 'flex',
    justifyContent: 'center',

    '&:hover': {
      backgroundColor: '#242424BF',
      transition: 'all 0.3s',
    },

    '&.disabled': {
      '&:hover': {
        background: 'none',
      },
    },
  },
  imageZoom: {
    cursor: 'pointer',
    width: '100%',
    height: '100%',
    alignSelf: 'center',

    '&:hover': {
      backgroundImage: `url(${ZoomIcon})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      transform: 'scale(1.25)',
      backgroundSize: 20,
      transition: 'transform 0.3s',
      transitionDelay: '0.1s',
    },

    '&.disabled': {
      background: 'none',
    },
  },
  btnProductId: {
    display: 'flex',
    textDecoration: 'none',
    cursor: 'pointer',
    border: 0,
    backgroundColor: 'white',
    padding: 0,
  },
  titleStyled: {
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 500,
    margin: '4px 0 6px',
    minHeight: 36,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  tooltipContainer: {
    position: 'relative',
    marginLeft: 50,
    justifyContent: 'center',
    display: 'flex',
    width: 231,
    height: 117,
  },
  tooltipText: {
    width: 231,
    height: 99,
    backgroundColor: '#FFF9EB',
    borderRadius: 4,
    boxShadow: '0px 2px 7px 0px #0000001F',
    position: 'absolute',
    zIndex: 1,
  },
  badge: {
    cursor: 'pointer',
    padding: '3px 8px 2px 8px',
    borderRadius: 11,
    fontSize: 12,
    lineHeight: '12px',
    color: '#FFFFFF',
    textTransform: 'uppercase',
    '&.colorGreen': {
      backgroundColor: '#21AD34',
      fontWeight: 700,
    },
    '&.colorBlue': {
      backgroundColor: '#2296F3',
      fontWeight: 700,
    },
    '&.colorYellow': {
      backgroundColor: '#FFE088',
      color: '#000000',
    },
    '&.colorDisabled': {
      backgroundColor: '#F6F6F7',
      color: '#7B7F82',
      fontWeight: 700,
      cursor: 'auto',
    },
  },
  badgeGrid: {
    minHeight: 20.02,
    alignItems: 'center',
    gap: 6,
    display: 'flex',
  },
  badgeMain: {
    textOverflow: 'ellipsis',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 1,
    display: '-webkit-box',
    overflow: 'hidden',
  },
  textTooltipPrimary: {
    color: '#484848',
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '15px',
    textTransform: 'uppercase',
    marginBottom: 8,
  },
  textTooltipSecondary: {
    color: '#242424',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '18px',
  },
  brandStyled: {
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 600,
    color: '#909599',
    margin: '0 0 5px 0',

    '&:hover': {
      color: '#000000',
      transition: 'all .5s',
    },

    '&.disabled': {
      '&:hover': {
        color: '#909599',
        cursor: 'auto',
      },
    },
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  chip: {
    borderRadius: 4,
    backgroundColor: '#E3E3E7',
    fontSize: 11,
    lineHeight: '17px',
    color: '#242424',
    fontWeight: 500,
    height: 18,
    padding: '2px 0px',
    width: 'min-content',
    cursor: 'pointer',
    '&.selected': {
      backgroundColor: '#242424',
      color: '#F8F9FA',
    },
  },
  brandAndVoltageBox: {
    minHeight: 50,
    '& h3': {
      textTransform: 'uppercase',
    },
  },
  qtyAndAdd: {
    padding: '10px 0 8px',
    display: 'flex',
    justifyContent: 'space-between',

    '&.disabled': {
      filter: 'grayscale(1)',
      opacity: '0.6',
    },
  },
  productButton: {
    border: 0,
    background: 'none',
  },
  popper: {
    zIndex: 0,
    top: '14px !important',
  },
  tooltip: {
    backgroundColor: '#008000',
    fontSize: 12,
  },
  pplIcon: {
    position: 'absolute',
    width: 120,
    height: 50,
    background: `url(${PplIcon})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    marginTop: -25,
    marginLeft: -46,

    [breakpoints.down('sm')]: {
      width: 109,
      height: 35,
      marginLeft: -15,
      marginTop: -30,
      backgroundSize: '100%',
    },
  },
  pplContainer: {
    position: 'relative',
  },
}));

export default useStyles;
