import {
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  modalInconsistency: {
    '& .MuiPaper-root': {
      padding: 14,
      borderRadius: 8,
      BorderColor: 'red',
      maxWidth: 600,
      width: '100%',
    },
  }
}));


export default useStyles;
