import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  showMoreBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    borderTop: '1px solid #CFCFD4',
    borderTopStyle: 'dashed',
    paddingTop: 8,
    margin: '0 12px',
  },
  showMoreBtn: {
    fontSize: 14,
    lineHeight: '21px',
    textTransform: 'math-auto',
    fontFamily: ['Poppins', 'sans-serif'],
    background: 'none',
    border: 'none',
    borderRadius: 4,
    padding: '4px 0',
    color: '#2296F3',
    fontWeight: 600,
    textDecoration: 'underline',
  },
}));

export default useStyles;
