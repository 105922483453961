import React from 'react';

import { Skeleton } from '@material-ui/lab';
import {
  Divider,
  Grid
} from '@material-ui/core';

export default function OrdersSkeleton() {

  return (
    <>
      <Skeleton variant="rect" width="100%" height={33} />
      <br/>
      <Skeleton variant="rect" width="100%" height={70} />
      <br/>
      <Skeleton variant="rect" width="100%" height={180} />
      <br/>
      <Divider />
      <br/>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Skeleton variant="rect" width="100%" height={120} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="rect" width="100%" height={120} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="rect" width="100%" height={120} />
        </Grid>
      </Grid>
      <br/>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Skeleton variant="rect" width="100%" height={50} />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="rect" width="100%" height={50} />
        </Grid>
      </Grid>
    </>
  );
}
