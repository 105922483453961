import {
  makeStyles,
  StepConnector,
  withStyles
} from '@material-ui/core';

const useStyles = makeStyles(({ theme }) => ({
  infoTitle: {
    padding: '12px 0',
    fontSize: 12,
    fontWeight: 600,
    color: '#242424',
  },
  infoContent: {
    fontSize: 12,
    fontWeight: 600,
    color: '#242424',
  },
  infoPayment: {
    display: 'flex',
    justifyContent: 'space-between',
    '& span': {
      fontWeight: 500
    }
  },

  infoPaper: {
    overflow: 'auto',
    padding: 8,
    height: 138,
    backgroundColor: '#F8F9FA',
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',

  },
  infoPaperCenter: {
    alignItems: 'center'
  },
  infoPayment: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  infoPaymentTitle: {
    fontSize: 12,
    fontWeight: 600,
    color: '#242424'
  },
  infoPaymentValue: {
    fontSize: 12,
    fontWeight: 600,
    color: '#242424'
  }
}));

export const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 23,
    '& .MuiStep-completed': {
      backgroundColor: 'red'
    }
  },
  active: {
    '& $line': {
      backgroundColor: '#1459FF'
    },
  },
  completed: {
    height: 50,
    '& $line': {
      backgroundColor: '#1459FF'
    },
  },
  line: {
    width: 'calc(100% + 32px)',
    marginLeft: '-18px',
    height: 3,
    border: 0,
    backgroundColor: '#ccc',
    borderRadius: 1,
  },
})(StepConnector);

export const useColorlibStepIconStyles = makeStyles({
  root: {
    height: 16,
    width: 16,
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    width: 40,
    height: 40,
    backgroundColor: '#1459FF'
  },
  completed: {
    backgroundColor: '#1459FF'
  },
});


export default useStyles;
