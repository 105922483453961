import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints }) => ({
  sortByBoxMain: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
  },
  sortByBox: {
    display: 'flex',
    gap: 16,
    alignItems: 'center',

    '& span:first-child': {
      fontSize: 14,
      lineHeight: '14px',
      width: 91,
      fontWeight: 600,
    },
  },
  sortByItem: {
    borderBottom: '1px solid #E3E3E7',
    fontSize: 12,
    lineHeight: '18px',
    textTransform: 'uppercase',
    color: '#242424',
    padding: '8px 8px 7px 8px',
  },
  selectSortByMenu: {
    boxShadow: 'none',
    border: '1px solid #E3E3E7',
    borderBottom: 0,
    top: '139px !important',

    '& ul': {
      padding: 0,
    },

    '&::-webkit-scrollbar': {
      width: 6,
    },

    '&::-webkit-scrollbar-thumb': {
      background: '#D9D9D9',
      borderRadius: 4,
    },

    [breakpoints.down(1800)]: {
      top: '311px !important',
    },
  },
  sortBySelect: {
    background: '#FFFFFF',
    padding: '10px 16px',
    textTransform: 'capitalize',
    height: 18,
  },
  sortByInput: {
    width: 260,
  },
}));

export default useStyles;
