/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';

import { Box, IconButton, Popover, Grid, MenuItem, Button, Typography} from '@material-ui/core';
import { Field, reduxForm, formValueSelector, change, untouch, reset } from 'redux-form';

import { connect, useDispatch } from 'react-redux';

import * as PropTypes from 'prop-types';

import moment from 'moment';

import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { addDays } from 'date-fns';
import { pt } from 'date-fns/locale';

import SearchIcon from '@material-ui/icons/Search';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { InputRender } from '../../../shared/fields';
import MultiSelectRender from '../../../shared/fields/MultiSelect';

import { 
  FILTER_SALLES_CHANNEL,
  FILTER_SELLERS,
  statusOrderOptions,
  statusRequestOptions,
  detailsOptions
} from '../../../shared/utils/creditRequests';
import { getCanalDeVendasFilterAction } from '../../salesChannel/actions';
import { getCanalDeVendas, getUnidadeDeNegocio } from '../../../shared/services/app';
import { getVendedoresSimplificado } from '../repository';

import useStyles from './style';
import { ReactComponent as TrashIcon } from '../../../assets/algolia/trash-icon.svg';

const FilterCreditRequest = ({
  user,
  open,
  sales,
  loading,
  filters,
  onSubmit,
  handleFilter,
  creditRequest,
  handleSubmit,
  setFilterDataFim,
  setFilterDataInicio,
  getCanalDeVendasFilter
}) => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const [dateOpen, setDateOpen] = useState(null);
  const [dateInicio, setDateInicio] = useState('');
  const [dateFim, setDateFim] = useState('');
  const canaisDeVendas = sales.canaisDeVendaFilter;

  const d = new Date();
  d.setDate(d.getDate() - 30);

  const [date, setDate] = useState([
    {
      startDate: d,
      endDate: new Date(),
      key: 'selection',
    },
  ]);


  const handleRequest = (dataInicio, dataFim) => {
    const dataFilterInicio = moment(dataInicio || date[0].startDate).format('DD-MM-YYYY');
    const dataFilterFim = moment(dataFim || date[0].endDate).format('DD-MM-YYYY');

    handleFilter(dataFilterInicio, dataFilterFim);
  };

  const showCanaisDeVenda = FILTER_SALLES_CHANNEL.includes(user.idCargo);
  const showVendedores = FILTER_SELLERS.includes(user.idCargo);

  const sellersEnabled = () => {
    const { idCanalVendas } = filters;

    if (showCanaisDeVenda && idCanalVendas && idCanalVendas != '') {
      return true;
    } else if (!showCanaisDeVenda ) {
      return true;
    }
    return false;
  }

  const [vendedores, setVendedores] = useState([]);

  const handleIdCanalVendasChange = async (idCanalVendas) => {
    setVendedores([]);
    dispatch(change('creditRequests/filter', 'idVendedor', ''));
    dispatch(untouch('creditRequests/filter', 'idVendedor'));
    handleGetVendedoresSimplificado(idCanalVendas)
  }

  useEffect(() => {
    setFilterDataInicio(moment(date[0].startDate).format('DD-MM-YYYY'));
    setFilterDataFim(moment(date[0].endDate).format('DD-MM-YYYY'));
    setDateInicio(moment(date[0].startDate).format('DD-MM-YYYY'));
    setDateFim(moment(date[0].endDate).format('DD-MM-YYYY'));
  }, [date]);

  useEffect(() => {
    if (open === true) {
      if (showCanaisDeVenda) {
        const unNegocio = getUnidadeDeNegocio();

        unNegocio && getCanalDeVendasFilter(unNegocio);
      }

      if (showVendedores && !showCanaisDeVenda) {
        handleGetVendedoresSimplificado();
      }
    }
  }, [open]);


  const handleGetVendedoresSimplificado = async (idCanalVendas) => {
    const vendedoresRequest = await getVendedoresSimplificado(idCanalVendas ?? getCanalDeVendas());
    setVendedores(vendedoresRequest);
  }

  const handleClearRequest = () => {
    setVendedores([]);
    const startDate = new Date().setDate(d.getDate() - 30);
    const endDate = new Date();

    dispatch(reset('creditRequests/filter'));

    handleRequest(startDate, endDate);
  }

  useEffect(() => {
    if (open === true) {
      if (showCanaisDeVenda) {
        const unNegocio = getUnidadeDeNegocio();

        unNegocio && getCanalDeVendasFilter(unNegocio);
      }

      if (showVendedores && !showCanaisDeVenda) {
        handleGetVendedoresSimplificado();
      }
    }
  }, [open]);

  return (
    <div
      className={`${classes.wrapper} ${open ? classes.wrapperOpened : null}`}
    >
      <form onSubmit={handleSubmit(() => onSubmit({ page: 1 }))}>
        <Box p={2}>
          <Grid className={classes.filter} container spacing={2}>
            <Grid item>
              <Field
                name="orcamento"
                component={InputRender}
                label="Orçamento"
              />
            </Grid>

            <Grid item>
              <Field
                name="idSolicitacao"
                component={InputRender}
                label="Id. Solicitação"
              />
            </Grid>

            <Grid item>
              <Field
                name="documento"
                component={InputRender}
                label="Dados do Cliente"
              />
            </Grid>

            <Grid item>
              <div>
                <div
                  className={classes.inputFakeDate}
                  onClick={(e) => setDateOpen(e.currentTarget)}
                >
                  <div>
                    <span>
                      {moment(dateInicio, 'DD-MM-YYYY').format('DD/MM/YYYY')}
                    </span>
                    <span> - </span>
                    <span>{moment(dateFim, 'DD-MM-YYYY').format('DD/MM/YYYY')}</span>
                  </div>
                  <ArrowDropDownIcon style={{ marginLeft: 30 }} />
                </div>
                <Popover
                  open={Boolean(dateOpen)}
                  anchorEl={dateOpen}
                  onClose={() => setDateOpen(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <DateRange
                    date={new Date()}
                    months={1}
                    name="date"
                    maxDate={addDays(date[0].startDate, 29)}
                    onChange={(item) => setDate([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={date}
                    rangeColors={['#FF5225']}
                    showMonthAndYearPickers={false}
                    locale={pt}
                  />
                </Popover>
              </div>
            </Grid>

            <Grid className={classes.actionsFilter} item xs={12} sm={3} flex alignItems="center">
              <IconButton
                type="submit"
                className={classes.searchButton}
                onClick={() => handleRequest()}
                disabled={loading}
              >
                <SearchIcon />
              </IconButton>
              <Button
                onClick={() => handleClearRequest()}
                className={classes.buttonCleanFilter}
                disabled={loading}
              >
                <TrashIcon/>
                <Typography>Limpar Filtro</Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </div>
  );
};

FilterCreditRequest.defaultProps = {
  loading: false,
};

FilterCreditRequest.propTypes = {
  page: PropTypes.string,
  open: PropTypes.bool.isRequired,
  isCheckout: PropTypes.bool,
  loading: PropTypes.bool,
  handleFilter: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFilterDataInicio: PropTypes.func,
  setFilterDataFim: PropTypes.func,
};

const seletor = formValueSelector('creditRequests/filter');
const mapStateToProps = (state) => ({

  ...state.main.checkoutsAndOrders,
  user: state.auth.login.user,
  sales: state.main.sales,
  creditRequest: state.main.creditRequests,
  filters: seletor(state, 'status', 'statusPedido', 'idCanalVendas', 'idVendedor'),
});

export default connect(mapStateToProps, {
  getCanalDeVendasFilter: getCanalDeVendasFilterAction
})(reduxForm({
  form: 'creditRequests/filter',
  destroyOnUnmount: false,
  initialValues: {
    dataInicio: moment(new Date()).subtract(1, 'month').format('DD-MM-YYYY'),
    dataFim: moment(new Date()).format('DD-MM-YYYY'),
    status: [],
    statusPedido: [],
    idCanalVendas: '',
    tipoPendencia: '',
    nomeCliente: '',
    idSolicitacao: '',
    documento: '',
    nomeCliente: '',
    idVendedor: '',
  },
})(FilterCreditRequest));
