import React from 'react';
import * as PropTypes from 'prop-types';
import useStyles from './styles';

const Divider = ({ children, classes }) => {
  const {
    border,
    divider,
    content,
  } = useStyles();

  return (
    <div className={`${divider} ${classes}`}>
      <div className={border} />
      {children && (
      <div className={content}>{children}</div>
      )}
      <div className={border} />
    </div>
  );
};

Divider.defaultProps = {
  children: null,
  classes: '',
};

Divider.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.string,
};

export default Divider;
