import React from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Box,
  Card,
  Chip,
  Grid,
  Tooltip as TooltipMUI,
} from '@material-ui/core';
import { connect } from 'react-redux';
import useStyles from './styles';
import {
  AddButton,
  Autocomplete,
  ContentText,
  DetailsDialog,
  Tooltip,
  VoltageProductDialog,
} from '../../../../../shared/components/algolia';
import { resetSearchAction } from '../../../actions';

const ProductListCard = ({
  className,
  product,
  userFilialId,
  pplInfo,
  loading,
  isFisicStore,
  listCard,
  handleVoltageModal,
  addProduct,
  productAvailable,
  data,
  priceEstablishment,
  voltageOptions,
  guaranteesOptions,
  localFilial,
  qtyLocalFilial,
  filialText,
  voltage,
  handleVoltage,
  handleStockTotal,
  copyToClipboard,
  openTooltip,
  handleTooltip,
  handleQtyRequested,
  handleOpenDialog,
  handleGuaranteeIdSelected,
  handleOpenVoltage,
  stockText,
  deliveryType,
}) => {
  const { qtyRequested, setQtyRequested } = handleQtyRequested;
  const { openDialog, setOpenDialog } = handleOpenDialog;
  const { guaranteeIdSelected, setGuaranteeIdSelected } = handleGuaranteeIdSelected;
  const { openVoltageDialog, setOpenVoltageDialog } = handleOpenVoltage;
  const { REACT_APP_URL_IMAGES } = process.env;
  const mainUrlImages = REACT_APP_URL_IMAGES || '';

  const classes = useStyles();
  const {
    badge,
    badgeMain,
    badgeGrid,
    brandAndVoltageBox,
    brandStyled,
    btnProductId,
    card,
    chip,
    copyIcon,
    flexColumn,
    gridCenter,
    imageHover,
    imageZoom,
    margin,
    productButton,
    productGrid,
    productGridBox,
    productGridBoxMain,
    productId,
    productImage,
    qtyAndAdd,
    relative,
    stockTotalStyled,
    titleStyled,
    valuesGrid,
    popper,
    tooltip,
    pplIcon,
    pplContainer,
    imageAndDescriptionBox,
    priceListCard,
  } = classes;

  return (
    <Box>
      {!!pplInfo && (
        <div className={pplContainer}>
          <span className={pplIcon} />
        </div>
      )}
      <Card className={`${className} ${card} ${(productAvailable && localFilial) && 'localFilial'} ${!productAvailable && 'disabled'}`} key={product.objectID}>
        <Box className={imageAndDescriptionBox}>
          <Grid className={`${gridCenter} ${relative}`}>
            {productAvailable ? (
              <button
                type="button"
                className={`${productButton} ${gridCenter} ${relative}`}
                onClick={() => setOpenDialog(true)}
              >
                <img
                  className={productImage}
                  src={`${mainUrlImages}${product.images[0]}`}
                  alt={product.title}
                />
                <div className={imageHover}>
                  <div className={imageZoom} />
                </div>
              </button>
            ) : (
              <button
                type="button"
                className={`${productButton} ${gridCenter} ${relative}`}
                onClick={() => setOpenDialog(true)}
              >
                <img
                  className={`${productImage} disabled`}
                  src={`${mainUrlImages}${product.images[0]}`}
                  alt={product.title}
                />
                <div className={`${imageHover} disabled`}>
                  <div className={`${imageZoom} disabled`} />
                </div>
              </button>
            )}
          </Grid>

          <Box className={productGridBoxMain}>
            <Box className={productGridBox}>
              <Grid className={productGrid}>
                <Grid className={valuesGrid}>
                  <span className={margin}>
                    {(product.rankingInfo || product.rankingData).margin.toFixed(2)}
                  </span>
                  <TooltipMUI
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltip}
                    open={openTooltip}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    classes={{ popper, tooltip }}
                    title="Código copiado"
                    placement="top"
                  >
                    <button type="button" className={btnProductId} onClick={copyToClipboard}>
                      <div className={productId}>
                        {`| ${product.objectID}`}
                      </div>
                      <div className={copyIcon} />
                    </button>
                  </TooltipMUI>
                </Grid>
                <span className={stockTotalStyled}>
                  {`${handleStockTotal.toLocaleString('pt-br')} un.`}
                </span>
              </Grid>

              <div className={badgeMain}>
                <Grid className={badgeGrid}>
                  {!productAvailable ? (
                    <Badge className={`${badge} colorDisabled`}>
                      Indisponível
                    </Badge>
                  ) : (
                    deliveryType.map((
                      {
                        name,
                        textPrimary,
                        textSecondary,
                        color,
                      },
                    ) => (
                      <Tooltip
                        key={name}
                        textPrimary={textPrimary}
                        textSecondary={!!textSecondary && textSecondary}
                      >
                        <Badge className={`${badge} ${color}`}>
                          <div dangerouslySetInnerHTML={{ __html: name }} />
                        </Badge>
                      </Tooltip>
                    ))
                  )}
                </Grid>
              </div>
            </Box>

            <Grid>
              <h2 className={titleStyled}>{`${product.title} ${product.model || ''}`}</h2>
              <Box className={brandAndVoltageBox}>
                <Badge>
                  <h3 className={`${brandStyled} ${!productAvailable && 'disabled'}`}>{product.brand.name}</h3>
                </Badge>
                <div className={flexColumn}>
                  {product.technicalSpecs.voltage && (
                    <Chip
                      size="small"
                      className={`${chip} ${voltage && 'selected'}`}
                      label={product.technicalSpecs.voltage}
                      key={product.objectID}
                      onClick={handleVoltage}
                      clickable={!productAvailable}
                      disabled={!productAvailable}
                    />
                  )}
                </div>
              </Box>
            </Grid>
          </Box>
        </Box>

        <Autocomplete
          disabled={!productAvailable}
          value={qtyRequested}
          setValue={setQtyRequested}
          onClickDisabled={(!productAvailable || loading)}
        />

        <ContentText
          priceProduct
          isFisicStore={isFisicStore}
          data={{
            ...priceEstablishment,
            pma: product.pma,
            available: productAvailable,
            ppl: pplInfo,
          }}
          className={priceListCard}
        />
        <Grid className={`${qtyAndAdd} ${(!productAvailable || loading) && 'disabled'}`}>
          {
            productAvailable && !loading ? (
              <Tooltip
                textSecondary={`${stockText} ${filialText} filial`}
              >
                <span>
                  <AddButton
                    localFilial={localFilial}
                    qty={qtyLocalFilial}
                    onClick={(!productAvailable || loading || !Number(qtyRequested))
                      ? () => {} : handleVoltageModal}
                    disabled={!productAvailable || loading || !Number(qtyRequested)}
                    listCard={listCard}
                  />
                </span>
              </Tooltip>
            ) : (
              <span>
                <AddButton
                  localFilial={localFilial}
                  qty={qtyLocalFilial}
                  disabled={!productAvailable || loading || !Number(qtyRequested)}
                />
              </span>
            )
          }
        </Grid>
        <VoltageProductDialog
          open={openVoltageDialog}
          setOpen={setOpenVoltageDialog}
          addProduct={addProduct}
          voltageOptions={voltageOptions}
          guaranteesOptions={guaranteesOptions}
          handleGuaranteeId={{ guaranteeIdSelected, setGuaranteeIdSelected }}
        />
        <DetailsDialog
          setOpen={setOpenDialog}
          open={openDialog}
          product={product}
          pplInfo={pplInfo}
          priceEstablishment={priceEstablishment}
          userFilialId={userFilialId}
          data={data}
          addProduct={handleVoltageModal}
          qtyProductRequested={qtyRequested}
          setQtyProductRequested={setQtyRequested}
          isFisicStore={isFisicStore}
          productAvailable={productAvailable}
        />
      </Card>
    </Box>
  );
};

ProductListCard.defaultProps = {
  className: '',
  userFilialId: 0,
  pplInfo: null,
  loading: false,
  isFisicStore: false,
  listCard: false,
};

ProductListCard.propTypes = {
  className: PropTypes.string,
  product: PropTypes.instanceOf(Object).isRequired,
  userFilialId: PropTypes.number,
  addProduct: PropTypes.func.isRequired,
  pplInfo: PropTypes.instanceOf(Object),
  loading: PropTypes.bool,
  isFisicStore: PropTypes.bool,
  listCard: PropTypes.bool,
  handleVoltageModal: PropTypes.func.isRequired,
  productAvailable: PropTypes.bool.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  priceEstablishment: PropTypes.instanceOf(Object).isRequired,
  guaranteesOptions: PropTypes.instanceOf(Array).isRequired,
  voltageOptions: PropTypes.instanceOf(Array).isRequired,
  localFilial: PropTypes.bool.isRequired,
  qtyLocalFilial: PropTypes.number.isRequired,
  filialText: PropTypes.string.isRequired,
  voltage: PropTypes.string.isRequired,
  handleVoltage: PropTypes.func.isRequired,
  handleStockTotal: PropTypes.func.isRequired,
  copyToClipboard: PropTypes.func.isRequired,
  openTooltip: PropTypes.bool.isRequired,
  handleTooltip: PropTypes.func.isRequired,
  handleQtyRequested: PropTypes.instanceOf(Object).isRequired,
  handleOpenDialog: PropTypes.instanceOf(Object).isRequired,
  handleGuaranteeIdSelected: PropTypes.instanceOf(Object).isRequired,
  handleOpenVoltage: PropTypes.instanceOf(Object).isRequired,
  stockText: PropTypes.string.isRequired,
  deliveryType: PropTypes.instanceOf(Array).isRequired,
};

const mapState = (state) => {
  const { snack } = state.main.app;
  const { checkout, loading } = state.main.checkout.geral;
  return ({
    ...state.main.app,
    checkout,
    loading,
    snack,
  });
};

export default connect(mapState, {
  resetSearch: resetSearchAction,
})(ProductListCard);
