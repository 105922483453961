import React, { useEffect, useRef, useState } from 'react';
import {
  useClearRefinements,
  useHierarchicalMenu,
  useNumericMenu,
  usePagination,
  useRange,
  useRefinementList,
  useSearchBox,
  useStats,
} from 'react-instantsearch';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Box, Button, IconButton, Paper, Popper,
} from '@material-ui/core';
import algoliasearch from 'algoliasearch';
import useStyles from './styles';
import { resetSearchAction, searchByCategoryAction } from '../../../../main/checkout/actions';
import {
  Collapse, envVariablesAlgolia,
  Hits, NumericMenu, RefinementList, Slider,
} from '../index';
import { ToggleRefinement } from '../filters';
import { handleRefinements } from '../../../utils/algolia';

const SearchBox = ({
  resetSearch: isResetSearch,
  resetSearchAction: resetSearch,
  searchByCategory,
  inputValue,
  categoryFilter,
  categoryList,
  user,
  client,
  disabled,
  isCheckout,
  facets,
}, props) => {
  const keepPinStorage = localStorage.getItem('keepPin');
  const { appId, indexName, writeApiKey } = envVariablesAlgolia;
  const classes = useStyles();
  const { query, refine } = useSearchBox(props);
  const { query: queryStats, nbHits } = useStats();
  const { refine: refineCategory } = useHierarchicalMenu({
    attributes: ['categoryLevels.lvl0', 'categoryLevels.lvl1', 'categoryLevels.lvl2'],
  });
  const { canRefine, refine: clearFilters } = useClearRefinements();
  const { refine: refinePage } = usePagination(props);
  const [value, setValue] = useState(query);
  const [hasHits, setHasHits] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [sameFilialCheckbox, setSameFilialCheckbox] = useState({
    stock: false,
    withdrawStore: false,
  });
  const [openMenuFilters, setOpenMenuFilters] = useState(false);
  const [refinements, setRefinements] = useState([]);
  const [indexFilialUser, setIndexFilialUser] = useState(0);
  const [keepPin, setKeepPin] = useState(JSON.parse(keepPinStorage));
  const [attributesForFaceting] = useState(facets);
  const clientAlgolia = algoliasearch(appId, writeApiKey);
  const indexAlgolia = clientAlgolia.initIndex(indexName);

  const inputRef = useRef(null);

  useRange({ attribute: 'price.promotionalDiscountPercentage' });
  useNumericMenu({
    attribute: 'rankingInfo.margin',
    items: [
      { label: '0 ~ 5', end: 5 },
      { label: '5 ~ 10', start: 5, end: 10 },
      { label: '10 ~ 20', start: 10, end: 20 },
      { label: '+ 20', start: 20.01 },
    ],
  });
  useNumericMenu({
    attribute: `priceEstablishments.${indexFilialUser}.term`,
    items: [
      { label: 'R$ 0 ~ R$ 20,00', end: 20 },
      { label: 'R$ 20,00 ~ R$ 40,00', start: 20, end: 40 },
      { label: 'R$ 40,00 ~ R$ 220,00', start: 40, end: 220 },
      { label: 'R$ 220,00 ~ R$ 430,00', start: 220, end: 430 },
      { label: 'R$ 430,00 ~ R$ 940,00', start: 430, end: 940 },
      { label: 'R$ 940,00 ~ R$ 1.750,00', start: 940, end: 1750 },
      { label: 'R$ 1.750,00 ~ R$ 19.000,00', start: 1750, end: 19000 },
    ],
  });
  useHierarchicalMenu({
    attributes: ['categoryLevels.lvl0', 'categoryLevels.lvl1', 'categoryLevels.lvl2'],
  });
  useRefinementList({ attribute: 'technicalSpecs.voltage' });
  useRefinementList({ attribute: 'brand.name' });

  const setQuery = (newQuery) => {
    setValue(newQuery);
    refine(newQuery);
  };

  const handleSearchBox = () => {
    setQuery(inputValue || '');

    if (inputRef.current) {
      inputRef.current.focus();
    }
    resetSearch(false);
  };

  const handleClearRefinements = () => {
    setSameFilialCheckbox({
      stock: false,
      withdrawStore: false,
    });
    if (categoryList) {
      searchByCategory('', null);
    }
    clearFilters();
  };

  const handlePriceProductFilter = () => {
    if (attributesForFaceting.length) {
      const filterPrice = `priceEstablishments.${indexFilialUser}.term`;
      const result = attributesForFaceting.some((filter) => filter === filterPrice);
      let attributes = [...attributesForFaceting];

      if (!result) attributes = [...attributesForFaceting, filterPrice];

      indexAlgolia.setSettings({
        attributesForFaceting: [
          ...attributes,
        ],
      }).catch((err) => console.log('error:', err));
    }
  };

  const handlePin = (status) => {
    localStorage.setItem('keepPin', status);
    setKeepPin(status);
  };

  useEffect(() => {
    if (query) refinePage(0);
  }, []);

  const handleFilters = () => (
    <Box className={classes.filterBox}>
      <Box className={classes.borderHeader}>
        <div className={classes.filterHeader}>
          <span className={classes.filtersIcon}>Filtros</span>
          <div className={classes.clearAndClose}>
            <button
              type="button"
              className={classes.clearRefinements}
              disabled={!canRefine}
              onClick={handleClearRefinements}
            >
              Limpar filtros
            </button>
            <Button
              classes={{ root: `${classes.pinBtn} ${keepPin && 'keepPin'}` }}
              onClick={() => handlePin(!keepPin)}
            />
            <div className={classes.clearAndClose}>
              <IconButton
                onClick={() => setOpenMenuFilters(false)}
                className={classes.close}
              />
            </div>
          </div>
        </div>
      </Box>
      <Box className={classes.filterBody}>
        {
          refinements.map((ref, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box key={`${index}`} className={classes.filtersBox}>
              {
                // eslint-disable-next-line array-callback-return,consistent-return
                ref.options.map(({ refinement }) => {
                  if (refinement) {
                    const {
                      isToggle, hasCollapse, isNumeric, isSlider,
                    } = refinement;

                    if (hasCollapse) {
                      return (
                        <Collapse
                          user={user}
                          customer={client}
                          key={refinement.title}
                          refinement={refinement}
                          handleSameFilialCheckbox={{
                            sameFilialCheckbox,
                            setSameFilialCheckbox,
                          }}
                        />
                      );
                    }
                    if (isSlider) {
                      return <Slider key={refinement.title} refinement={refinement} />;
                    }
                    if (isNumeric) {
                      return <NumericMenu key={refinement.title} refinement={refinement} />;
                    }
                    if (isToggle) {
                      return (
                        <ToggleRefinement key={refinement.title} refinement={refinement} />
                      );
                    }
                    return <RefinementList key={refinement.title} refinement={refinement} />;
                  }
                })
              }
            </Box>
          ))
        }
      </Box>
    </Box>
  );

  useEffect(() => {
    if ((value === queryStats) && categoryFilter) {
      if (categoryList.detail) {
        refineCategory(categoryFilter);
        searchByCategory(null, categoryList);
      }
    }
  }, [value, queryStats, categoryFilter, categoryList]);

  useEffect(() => {
    if (isResetSearch) {
      handleSearchBox();
    } else if ((value === queryStats) && categoryFilter) {
      if (categoryList.detail) {
        refineCategory(categoryFilter);
        searchByCategory('', categoryList);
      }
    }
  }, [isResetSearch]);

  useEffect(() => {
    setHasHits(!!nbHits);
    if (!nbHits) setOpenMenuFilters(keepPin);
  }, [nbHits]);

  useEffect(() => {
    handleClearRefinements();
    setHasHits(!!nbHits);
  }, [!nbHits]);

  useEffect(() => {
    if (indexFilialUser && facets) {
      handlePriceProductFilter();
    }
    setRefinements(handleRefinements(indexFilialUser));
  }, [indexFilialUser]);

  useEffect(() => {
    if (keepPin) setOpenMenuFilters(keepPin);
  }, [keepPin]);

  return (
    <div className={classes.searchBoxContainer}>
      <form
        action=""
        role="search"
        onSubmit={(event) => {
          event.preventDefault();
          event.stopPropagation();

          if (inputRef.current) {
            inputRef.current.blur();
          }
        }}
        className={classes.searchBox}
      >
        <input
          ref={inputRef}
          placeholder="O que você procura..."
          type="search"
          value={value}
          onChange={(event) => {
            setAnchorEl(anchorEl ? null : event.currentTarget);
            setQuery(event.currentTarget.value);
            if (canRefine) {
              handleClearRefinements();
            }
          }}
          className={classes.searchInput}
          disabled={disabled}
        />
        <button aria-label="search product" type="submit" className={classes.searchButton} />
      </form>
      <Popper
        open={hasHits}
        className={classes.hitsPopper}
        anchorEl={anchorEl}
      >
        <Paper
          className={`${classes.hitsPaper} ${!openMenuFilters && 'hidden'}`}
        >
          <Box className={`${classes.filtersMenuMain} ${!openMenuFilters && 'hidden'}`}>
            <Box className={classes.filtersMenu}>
              <Box>
                {handleFilters()}
              </Box>
            </Box>
          </Box>
          <Hits
            user={user}
            customer={client}
            hasHits={hasHits}
            isCheckout={isCheckout}
            handleSameFilialCheckbox={{
              sameFilialCheckbox,
              setSameFilialCheckbox,
            }}
            setIndexFilialUser={setIndexFilialUser}
            handleOpenMenuFilters={{ openMenuFilters, setOpenMenuFilters }}
            hasFilters
          />
        </Paper>
      </Popper>
    </div>
  );
};

SearchBox.defaultProps = {
  disabled: false,
  isCheckout: false,
  categoryFilter: '',
  categoryList: null,
  client: null,
};

SearchBox.propTypes = {
  resetSearch: PropTypes.bool.isRequired,
  resetSearchAction: PropTypes.func.isRequired,
  searchByCategory: PropTypes.func.isRequired,
  inputValue: PropTypes.string.isRequired,
  categoryFilter: PropTypes.string,
  categoryList: PropTypes.instanceOf(Array),
  user: PropTypes.instanceOf(Object).isRequired,
  client: PropTypes.instanceOf(Object),
  disabled: PropTypes.bool,
  isCheckout: PropTypes.bool,
  facets: PropTypes.instanceOf(Array).isRequired,
};

const mapState = (state) => {
  const {
    resetSearch,
    inputSearch,
    category,
    categoryHierarchy,
  } = state.main.checkout.geral;

  return ({
    resetSearch,
    inputValue: inputSearch,
    categoryFilter: category,
    categoryList: categoryHierarchy,
    user: state.auth.login.user,
    client: state.authClient.client,
  });
};

export default connect(mapState, {
  resetSearchAction,
  searchByCategory: searchByCategoryAction,
})(SearchBox);
