import React, { useEffect, useState } from 'react';
import { useHierarchicalMenu } from 'react-instantsearch';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-cycle
import { connect } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import { HierarchicalList } from '../index';
import useStyles from './styles';
import { searchByCategoryAction } from '../../../../../main/checkout/actions';

const HierarchicalMenu = ({ refinement, searchByCategory, categoryList }, props) => {
  const classes = useStyles();
  const [selected, setSelected] = useState('');
  const {
    showMoreBox,
    showMoreBtn,
  } = classes;

  const {
    attributes,
    showMore,
    showMoreLimit,
    limit,
  } = refinement;

  const {
    items,
    refine,
    canToggleShowMore,
    toggleShowMore,
    isShowingMore,
    createURL,
  } = useHierarchicalMenu({
    attributes,
    showMore,
    showMoreLimit,
    limit,
    sortBy: ['isRefined:desc'],
    ...props,
  });

  useEffect(() => {
    // atualiza objeto redux para a funcionalidade dos filtros categoria > subcategoria > setor
    // nos detalhes do produto - para não funcionar como checkbox os campos

    const hasRefined = items.some((item) => item.isRefined);

    if (selected && hasRefined) {
      const array = selected.split(/\s>\s/g);
      const isSame = !!categoryList && !!selected.match(`${categoryList.value}`);

      const newArray = [];

      if (isSame) {
        switch (categoryList.type) {
          case 1:
            newArray.push({
              name: array[array.length - 1],
              value: array.length !== 1 ? selected : '',
              type: array.length,
              detail: false,
            });
            break;
          case 2:
          // eslint-disable-next-line no-case-declarations
            let newValue = '';
            if (array.length === 2) {
              newValue = `${array[0]} > ${array[1]}`;
            } else if (array.length === 3) {
              newValue = selected;
            }
            newArray.push({
              name: array[array.length !== 3 ? 0 : 2],
              value: newValue,
              type: array.length !== 3 ? 1 : 3,
              detail: false,
            });
            break;
          default:
          // eslint-disable-next-line no-case-declarations
            let newValueSector = '';
            if (array.length === 2) {
            // eslint-disable-next-line prefer-destructuring
              newValueSector = array[0];
            } else if (array.length === 3) {
              newValueSector = `${array[0]} > ${array[1]}`;
            }
            newArray.push({
              name: array[array.length > 3 ? 1 : 0],
              value: newValueSector,
              type: array.length !== 3 ? 1 : 2,
              detail: false,
            });
            break;
        }
        searchByCategory(selected, ...newArray);
      } else {
        searchByCategory(selected,
          {
            name: array[array.length - 1],
            value: selected,
            type: array.length,
            detail: false,
          });
      }
    }
  }, [items]);

  useEffect(() => {
    if (selected && categoryList) {
      setSelected('');
    }
  }, [categoryList]);

  return (
    <Box>
      <HierarchicalList
        items={items}
        onNavigate={refine}
        createURL={createURL}
        setSelected={setSelected}
      />
      {showMore && (
        <Box className={showMoreBox}>
          <button
            type="button"
            className={showMoreBtn}
            disabled={!canToggleShowMore}
            onClick={toggleShowMore}
          >
            {isShowingMore ? 'Ver menos' : 'Ver mais'}
          </button>
        </Box>
      )}
    </Box>
  );
};

HierarchicalMenu.defaultProps = {
  categoryList: null,
};

HierarchicalMenu.propTypes = {
  refinement: PropTypes.instanceOf(Object).isRequired,
  searchByCategory: PropTypes.func.isRequired,
  categoryList: PropTypes.instanceOf(Array),
};

const mapState = (state) => {
  const {
    categoryHierarchy,
  } = state.main.checkout.geral;

  return ({
    categoryList: categoryHierarchy,
  });
};

export default connect(mapState, {
  searchByCategory: searchByCategoryAction,
})(HierarchicalMenu);
