import { cpf, cnpj } from 'cpf-cnpj-validator';

export const required = (value) => (
  ((typeof value === 'string' && value.trim()) || typeof value === 'number' ? undefined : 'Esse campo é obrigatorio')
);

export const requiredAllTypes = (value) => (value ? undefined : 'Esse campo é obrigatorio');

export const max10Mb = (value) => {
  if (value) {
    const bytes = (value.size / (1024 * 1024));

    if (bytes > 10) {
      return 'Arquivo excede o tamanho máximo permitido de 10 MB.';
    } return undefined;
  } return undefined;
};

export const maxLength = (max) => (value) => (value && value.length > max
  ? `Deve ter ${max} caracteres ou menos`
  : undefined);
export const maxLength5 = maxLength(5);
export const maxLength15 = maxLength(15);
export const minLength = (min) => (value) => {
  const inputValue = typeof value === 'string' ? value.trim() : value;
  return (inputValue && inputValue.length < min
    ? `Deve ter ${min} caracteres ou mais`
    : undefined);
};
export const minLength2 = minLength(2);
export const minLength3 = minLength(3);
export const minLength8 = minLength(8);

export const minLength6 = minLength(6);
export const minLength10 = minLength(10);
export const maxLength14 = maxLength(14);
export const maxLenght9 = maxLength(9);
export const maxLenght100 = maxLength(100);

// eslint-disable-next-line no-restricted-globals
export const number = (value) => (value && isNaN(Number(value) && value.match(/\D/g))
  ? 'Deve ser um número'
  : undefined);
export const minValue = (min) => (value) => (value && value < min
  ? `Precisa ser ao menos ${min}`
  : undefined);
export const minValue13 = minValue(13);
export const email = (value) => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
  ? 'Endereço de e-mail inválido!'
  : undefined);
export const tooYoung = (value) => (value && value < 13
  ? 'Você não tem a idade minima necessária!'
  : undefined);
export const alphaNumeric = (value) => (value && /[^a-zA-Z0-9 ]/i.test(value)
  ? 'Apenas caracteres alfanumericos'
  : undefined);
export const alphabetic = (value) => (value && /[^a-zA-Z ]/i.test(value)
  ? 'Apenas caracteres alfabéticos'
  : undefined);

export const phoneNumber = (value) => (
  value && !/^([0-9][0-9]{9,11})$/i.test(value)
    ? 'Número telefonico inválido, precisa ter mais que 10 digitos'
    : undefined
);
export const cpfValid = (value = '') => {
  const parsedValue = String(value).replace(/([\D])/g, '');
  return parsedValue && (
    cpf.isValid(parsedValue) ? undefined : 'Este CPF não é válido!'
  );
};

export const cnpjValid = (value = '') => {
  const parsedValue = String(value).replace(/([\D])/g, '');
  return parsedValue && (
    cnpj.isValid(parsedValue) ? undefined : 'Este CNPJ não é válido!'
  );
};

export const differentName = (value, name) => (name === value ? 'Nome Social deve ser diferente do Nome Completo' : undefined);

export const cellphone = (value) => {
  const valueTrim = value && value.replace(/\D/g, '');
  if (valueTrim && valueTrim.length >= 3) {
    const initialValue = valueTrim.slice(2)[0];

    if (initialValue.match(/(9|8|7|6)/)) {
      return !/^([0-9][0-9]{10})$/i.test(valueTrim) ? 'Número telefonico inválido, precisa ter 11 digitos' : undefined;
    }
    return !/^([0-9][0-9]{9})$/i.test(valueTrim) ? 'Número telefonico inválido, precisa ter 10 digitos' : undefined;
  }
  return valueTrim && !/^([0-9][0-9]{9,11})$/i.test(valueTrim)
    ? 'Número telefonico inválido.'
    : undefined;
};
