export const isExclusivePosition = (idCargo) => {
  const exclusives = [
    { id: 2, nome: 'Supervisor Vendas' },
    { id: 7, nome: 'Coordenador de Vendas' },
    { id: 8, nome: 'Gerente de Vendas' },
    { id: 15, nome: 'Gerente Distrital' },
    { id: 18, nome: 'Gerente Geral de Lojas' },
  ];

  return exclusives.some(({ id }) => id === Number(idCargo));
};

export const findStatusPayment = (statusId) => {
  const list = [
    {
      id: 1,
      descricao: 'Aguardando pagamento',
      color: 'rgb(254 189 1)',
      colorName: 'yellow',
    },
    {
      id: 2,
      descricao: 'Pagamento aprovado',
      color: 'rgb(33 173 52)',
      colorName: 'green',
    },
    {
      id: 3,
      descricao: 'Pagamento cancelado',
      color: 'rgb(244 40 45)',
      colorName: 'red',
    },
  ];

  return list.find(({ id }) => id === statusId);
};

export const trackingList = [
  { key: 'AES', value: '(AES) - Aguardando estoque' },
  { key: 'AEW', value: '(AEW) - Aguardando WMS' },
  { key: 'ASE', value: '(ASE) - A Separar' },
  { key: 'CAN', value: '(CAN) - Entrega cancelada' },
  { key: 'DEVP', value: '(DEVP) - Devolucao Parcial' },
  { key: 'DEVT', value: '(DEVT) - Devolucao Total' },
  { key: 'ENT', value: '(ENT) - Entregue ao Cliente' },
  { key: 'ETR', value: '(ETR) - Entregue a Transportadora' },
  { key: 'IVE', value: '(IVE) - Item virtual enviado' },
  { key: 'NFA', value: '(NFA) - NFe Aprovada' },
  { key: 'NFS', value: '(NFS) - NF emitida' },
  { key: 'PAP', value: '(PAP) - Pedido aprovado' },
  { key: 'PCE', value: '(PCE) - Problemas com a entrega' },
  { key: 'PDL', value: '(PDL) - Pedido Liquidado' },
  { key: 'PEI', value: '(PEI) - Pedido Incluido' },
  { key: 'PNA', value: '(PNA) - Pagamento nao aprovado' },
  { key: 'POK', value: '(POK) - Pagamento OK' },
  { key: 'PRP', value: '(PRP) - Pronto para Analise Pagto' },
  { key: 'RET', value: '(RET) - Mercadoria retirada no CD/LOJA' },
  { key: 'RIE', value: '(RIE) - Recebimento de Insucesso de Entrega' },
  { key: 'ROU', value: '(ROU) - Roubo' },
  { key: 'SEP', value: '(SEP) - Separado' },
  { key: 'WMS', value: '(WMS) - No WMS' },
  { key: 'PEA', value: '(PEA) - Pedido em Análise' },
  { key: 'PGA', value: '(PGA) - Pagamento em Análise' },
  { key: 'I19', value: '(I19) - Em devolução' },
  { key: 'I55', value: '(I55) - Carga incompleta' },
  { key: 'I20', value: '(I20) - Entrega agendada' },
  { key: 'I07', value: '(I07) - Mantém status anterior' },
  { key: 'I34', value: '(I34) - Não visitado' },
  { key: 'I15', value: '(I15) - Em transferência' },
  { key: 'I69', value: '(I69) - Despachado' },
  { key: 'I63', value: '(I63) - Devolvido' },
  { key: 'I24', value: '(I24) - Carga recusada pelo destinatário' },
  { key: 'I36', value: '(I36) - Destinatário não localizado' },
  { key: 'I26', value: '(I26) - Destinatário desconhecido' },
  { key: 'I57', value: '(I57) - Entrega efetuada errada' },
  { key: 'I70', value: '(I70) - Endereço inválido' },
  { key: 'I31', value: '(I31) - Difícil acesso' },
  { key: 'I52', value: '(I52) - Carga recusada pela transportadora' },
  { key: 'I27', value: '(I27) - Destinatário faleceu/faliu' },
  { key: 'I30', value: '(I30) - Aguardando dados' },
  { key: 'I17', value: '(I17) - Entrada filial destino' },
  { key: 'I13', value: '(I13) - Emissão do CT-e / NFS-e' },
  { key: 'I33', value: '(I33) - Destinatário mudou-se' },
  { key: 'I44', value: '(I44) - Fatores naturais' },
  { key: 'I35', value: '(I35) - Carga travada' },
  { key: 'I56', value: '(I56) - Em processo de investigação' },
  { key: 'I40', value: '(I40) - Solicitação entrega posterior' },
  { key: 'I59', value: '(I59) - Em rota de entrega' },
  { key: 'I03', value: '(I03) - Em trânsito' },
  { key: 'I25', value: '(I25) - Destinatário ausente' },
  { key: 'I32', value: '(I32) - Feriado' },
  { key: 'I06', value: '(I06) - Saiu para entrega' },
  { key: 'I49', value: '(I49) - Cancelado pelo remetente' },
  { key: 'I38', value: '(I38) - Destinatário sem identificação' },
  { key: 'I01', value: '(I01) - Averiguar falha na entrega' },
  { key: 'I16', value: '(I16) - Carga redespachada' },
  { key: 'I21', value: '(I21) - Problema na postagem' },
  { key: 'I09', value: '(I09) - Coleta chegando' },
  { key: 'I58', value: '(I58) - Greve geral' },
  { key: 'I79', value: '(I79) - Fechado' },
  { key: 'I41', value: '(I41) - Carga vencida' },
  { key: 'I11', value: '(I11) - Entrada filial' },
  { key: 'I47', value: '(I47) - Carga errada' },
  { key: 'I42', value: '(I42) - Problemas pagamento de frete' },
  { key: 'I04', value: '(I04) - Entregue' },
  { key: 'I14', value: '(I14) - Saída filial' },
  { key: 'I64', value: '(I64) - Extravio' },
  { key: 'I78', value: '(I78) - Cancelado' },
  { key: 'I46', value: '(I46) - Aguardando instrução' },
  { key: 'I51', value: '(I51) - Problema operacional' },
  { key: 'I28', value: '(I28) - Duplicidade de carga' },
  { key: 'I50', value: '(I50) - Recusado pelo remetente' },
  { key: 'I23', value: '(I23) - Área não atendida' },
  { key: 'I81', value: '(I81) - Sinistro confirmado' },
  { key: 'I48', value: '(I48) - Problema fiscal' },
  { key: 'I39', value: '(I39) - Endereço não localizado' },
  { key: 'I43', value: '(I43) - Estabelecimento fechado' },
  { key: 'I37', value: '(I37) - Endereço insuficiente' },
  { key: 'I61', value: '(I61) - Entregue fora do destino' },
  { key: 'I10', value: '(I10) - Coleta realizada' },
  { key: 'I12', value: '(I12) - Processamento na filial' },
  { key: 'I18', value: '(I18) - Reentrega solicitada' },
  { key: 'I65', value: '(I65) - Roubo' },
  { key: 'I22', value: '(I22) - Aguardando retirada' },
  { key: 'I05', value: '(I05) - Falha na entrega' },
  { key: 'I29', value: '(I29) - Atraso transportador' },
  { key: 'I53', value: '(I53) - Área de risco' },
  { key: 'I54', value: '(I54) - Correção informação' },
  { key: 'I62', value: '(I62) - Avaria' },
  { key: 'I66', value: '(I66) - Carga descartada' },
];
