import React, { useState, useEffect } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  Button,
  DialogTitle,
  IconButton,
  Grid,
  DialogContent,
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Field, reduxForm,
} from 'redux-form';
import { Close } from '@material-ui/icons';
import useStyles from './style';
import { tradeValueProductAction } from '../../actions';
import { toMoney } from '../../../../shared/utils/parsers';
import { InputCurrencyRenderOutlined } from '../../../../shared/fields';
import apiService from '../../../../shared/services/apiService';
import { getUnidadeDeNegocio} from '../../../../shared/services/app';

const ModalTrocaValor = ({
  open, setOpen, valoraPrazo, tradeValueProduct, codProduto, checkoutId, idItemBseller, idUser, margin,
  justify, amount, discountPermission, percent
}) => {
  const classes = useStyles();
  const [justificativa, setJustificativa] = useState('');
  const [novoValor, setNovoValor] = useState('');
  const [valorOriginal, setValorOriginal] = useState('');
  const [discountMessage] = useState(true);
  const handleToggle = () => {
    setOpen(false);
  };

  const [cargo, setCargo] = useState(null);

  const margemPermitida = () => {
    let desconto = valorOriginal-novoValor > valorOriginal*0.15;
    let idUnidadeDeNegocio = getUnidadeDeNegocio();
    let margem = margin;
    let permissao = true;
    if (idUnidadeDeNegocio < 2) {
      if (margem < 20 && (cargo != 8 && cargo != 7 && cargo != 4 && cargo != 11)) {
        permissao = false;
      } else {
        if (!desconto && (cargo != 2 && cargo != 8 && cargo != 7 && cargo != 4 && cargo != 11)) {
          permissao = false;
        }
        if (desconto && (cargo != 8 && cargo != 7 && cargo != 4 && cargo != 11)) {
          permissao = false;
        }
      }
    } else {
      if (margem < 20 && (cargo != 7 && cargo != 8 && cargo != 15)) {
        permissao = false;
      } else {
        if (!desconto && (cargo != 4 && cargo != 11 && cargo != 7 && cargo != 8 && cargo != 15)) {
          permissao = false;
        }
        if (desconto && (cargo != 7 && cargo != 8 && cargo != 15)) {
          permissao = false;
        }
      }
    }
    return permissao;
  };

  useEffect(() => {
    if (open == true) {
      if (idUser != null && cargo == null) {
        const getDetailsVendedor = async ({ idVendedor }) => {
          const {
            data: { results },
          } = await apiService.get(`/vendedores/${idVendedor}`);
              setCargo(results.idCargo);
        };

        getDetailsVendedor({idVendedor:idUser});


      }
      const getPrecoOriginal = async () => {
        setValorOriginal('');
        const {
          data: { results },
        } = await apiService.get(`/carrinho/altera-preco-produto/valor-original/checkoutid/${checkoutId}/codproduto/${codProduto}`);

        if (results != undefined && results != '' && results > 0) {
          setValorOriginal(results);
        } else {
          setValorOriginal(valoraPrazo);
        }


      };
      getPrecoOriginal();
      setNovoValor(0);
      setJustificativa('');
    }

  }, [open, valoraPrazo]);

  const handleValue = ({ target: { value } }) => {
    const parsedValue = +value.replace(/\D/g, '') / 100;
    setNovoValor(parsedValue);
  };

  const functionTradeValue = () => {
    tradeValueProduct({
      checkoutId,
      codProduto,
      idItemBseller,
      valorAnterior: valorOriginal != '' && valorOriginal != null ? valorOriginal : valoraPrazo,
      valorAtribuido: novoValor,
      motivoSolicitacao: justificativa,
      justify,
      amount,
      discountMessage,
      percent,
    });
    handleToggle();
  };
  return (
    <>
      <Dialog open={open} onClose={handleToggle} maxWidth="xs" fullWidth>
        <Box position="relative">
          <Box position="absolute" top={4} right={4}>
            <IconButton onClick={handleToggle}>
              <Close />
            </IconButton>
          </Box>

          <DialogTitle>
            Alterar Valor do Produto
          </DialogTitle>
          <DialogContent dividers>
            <Grid item container spacing={2} xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}>
            <Grid item xs={10}>
                <TextField
                  className={classes.field}
                  size="small"
                  fullWidth
                  variant="outlined"
                  disabled
                  value={"Margem: "+margin}
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  label="Valor Original"
                  className={classes.field}
                  size="small"
                  fullWidth
                  variant="outlined"
                  disabled
                  value={toMoney(valorOriginal != '' && valorOriginal != null  ? valorOriginal : valoraPrazo)}
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  label="Novo Valor"
                  className={classes.field}
                  size="small"
                  fullWidth
                  onChange={handleValue}
                  variant="outlined"
                  value={toMoney(novoValor)}
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  label="Justificativa"
                  className={classes.field}
                  size="small"
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={4}
                  value={justificativa}
                  onChange={(e) => setJustificativa(e.target.value)}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleToggle} type="button">
              Fechar
            </Button>
            <Button color="primary" onClick={functionTradeValue} disabled={(!(justificativa.length > 5 && novoValor > 0 && justificativa.length < 400) || (novoValor < valorOriginal))}>
              Salvar
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

ModalTrocaValor.defaultProps = {
  open: false,
};

ModalTrocaValor.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  discountPermission: PropTypes.bool.isRequired,
  valoraPrazo: PropTypes.number.isRequired,
  margin: PropTypes.number.isRequired,
  tradeValueProduct: PropTypes.func.isRequired,
  codProduto: PropTypes.number.isRequired,
  idUser: PropTypes.number.isRequired,
  checkoutId: PropTypes.string.isRequired,
  idItemBseller: PropTypes.number.isRequired,
  justify: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  percent: PropTypes.number.isRequired,
};


export default connect((state) => ({
  ...state.main.checkout.advanced,
  idUser: state.auth.login.user.idVendedor,
}), {
  tradeValueProduct: tradeValueProductAction,
})(reduxForm({
  form: 'checkout/advanced',
})(ModalTrocaValor));
