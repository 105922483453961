import React from 'react';
import Typography from '@material-ui/core/Typography';
import {
  Box,
  Grid,
  Paper
} from '@material-ui/core';

import {
  toCEP,
  toMoney
} from '../../../../../../shared/utils/parsers';

import useStyles from './styles';
import PropTypes from 'prop-types';

const OrderInfo = ({ enderecoEntrega, pagamentos }) => {
  const classes = useStyles();

  const mappingPayment = {
    B: 'Boleto',
    PX: 'Pix',
    CC: 'Crédito',
    DB: 'Débito',
    CS: 'Consórcio',
    DC: 'Depósito',
    BP: 'Boleto Prazo',
    CP: 'Cupom',
    DN: 'Dinheiro',
    VA: 'Vale',
  }

  const showInstallments = (type) => type === 'CC' || type === 'BP';

  const {
    infoTitle,
    infoContent,
    infoPayment,
    infoPaymentTitle,
    infoPaymentValue,
    infoContainer,
    infoPaper,
  } = classes;

  const {
    cep,
    cidade,
    bairro,
    estado,
    numero,
    endereco,
  } = enderecoEntrega;

  const {
    frete,
    subTotal,
    desconto,
    valorVoucherCupom
  } = pagamentos[0];

  return (
    <div>
      <Grid container className={infoContainer} spacing={2}>
        <Grid item xs={4}>
          <Typography className={infoTitle}>Endereço para entrega:</Typography>
          <Paper className={infoPaper}>
            <Typography className={infoContent}>{endereco}, {numero}</Typography>
            <Typography className={infoContent}>{bairro}</Typography>
            <Typography className={infoContent}>{cidade} - {estado}</Typography>
            <Typography className={infoContent}>{toCEP(cep)}</Typography>
          </Paper>
        </Grid>

        <Grid item xs={4}>
          <Typography className={infoTitle}>Detalhes do pagamento:</Typography>
          <Paper className={infoPaper}>
            <Typography className={`${infoContent} ${infoPayment}`}>Subtotal: <span>{toMoney(subTotal)}</span></Typography>
            <Typography className={`${infoContent} ${infoPayment}`}>Frete:<span>{toMoney(frete)}</span></Typography>
            <Typography className={`${infoContent} ${infoPayment}`}>Desconto:<span>{toMoney(desconto)}</span></Typography>
            <Typography className={`${infoContent} ${infoPayment}`}>Vale:<span>{toMoney(valorVoucherCupom)}</span></Typography>
          </Paper>
        </Grid>

        <Grid item xs={4}>
          <Typography className={infoTitle}>Forma de pagamento:</Typography>
          <Paper className={`${infoPaper} center`}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems={pagamentos.length > 1 ? 'flex-start' : 'center'}
              justifyContent="space-between"
            >
              {pagamentos.map((pagamento, index) => {
                const {
                  type,
                  installmentValue,
                  installmentQuantity
                } = pagamento;

                return (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                    key={index}
                    mb={1}
                  >
                    <Typography className={infoPaymentTitle} style={{ marginLeft: pagamentos.length === 1 ? 8 : 0 }}>
                      {mappingPayment[type]}
                    </Typography>
                    <Typography className={infoPaymentValue} style={{ marginLeft: 8 }}>
                      {`${showInstallments(type) ? (installmentQuantity + 'x de') : ''} ${toMoney(installmentValue)}`}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Paper>
        </Grid>

      </Grid>
    </div>
  );
}


OrderInfo.propTypes = {
  enderecoEntrega: PropTypes.object,
  pagamentos: PropTypes.array
};

export default OrderInfo;