import { makeStyles } from '@material-ui/core';
import {
  AttachIcon, CommentIcon, EditIcon, ShowIcon,
} from '../../assets';

const useStyles = makeStyles(({ breakpoints }) => ({
  pageSection: {
    '& .MuiTableContainer-root': {
      borderRadius: '24px',
      width: 'auto',
      margin: '26px',
      border: '1px solid #CFCFD4',
    },
  },
  name: {
    [breakpoints.down('sm')]: {
      width: 'auto',
    },
    [breakpoints.up('md')]: {
      width: '25%',
    },
  },
  amount: {
    [breakpoints.down('sm')]: {
      width: 'auto',
    },
    [breakpoints.up('md')]: {
      width: '15%',
    },
  },
  points: {
    [breakpoints.down('sm')]: {
      width: 'auto',
    },
    [breakpoints.up('md')]: {
      width: '10%',
    },
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  customerCell: {
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  tableHeadRow: {
    '& .MuiTableCell-root': {
      fontWeight: 'bold',
      padding: '10px 20px',
      background: '#F8F9FA',
    },
  },
  tableHeadCell: {
    textAlign: 'center',
    width: 'calc(100% / 8)',
  },
  tableBodyRow: {
    background: '#FFFFFF',
    borderTop: '1px solid rgba(224, 224, 224, 1)',

    '& .MuiTableCell-root': {
      padding: '0',
      height: 64,
      border: 0,
    },

    '& .noBorder': {
      borderBottom: 'none',
    },

    '&.selectedRow': {
      background: '#F8F9FA',
    },
  },
  tableBodyCell: {
    textAlign: 'center',
    width: 'calc(100% / 8)',

    '& .MuiChip-root': {
      height: '40px',
      borderRadius: '20px',
    },

    '& .MuiChip-label': {
      fontSize: '12px',
    },

    '&.noBorder': {
      borderBottom: 'none',
    },
  },
  tableBodyCellCustomer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',

    '& span:first-child': {
      width: '100%',
      display: '-webkit-box',
      overflow: 'hidden',
      wordBreak: 'break-all',
      textOverflow: 'ellipsis',
      textTransform: 'capitalize',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 1,
    },
  },
  tableBodyCellCanalVenda: {
    textAlign: 'center',

    '& span': {
      whiteSpace: 'normal',
    },
  },
  orderStatus: {
    width: '12px',
    height: '12px',
    fontSize: '7px',
    marginRight: '4px',
    borderRadius: '20px',
  },
  tableBodyCellClickable: {
    textAlign: 'start',
    '&:hover': {
      '& .MuiBox-root': {
        color: '#FF5225',
        transition: 'color .1s',
      },
    },
    fontWeight: '600',
    cursor: 'pointer',
    color: 'primary',
  },
  tableBodyCellClickableDisabled: {
    textAlign: 'start',
    fontWeight: '600',
    cursor: 'normal',
    color: 'primary',
  },
  message: {
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: '600',
    background: 'white',
    color: '#FF5225',
  },
  showResultBtn: {
    border: 0,
    cursor: 'pointer',
    background: `url(${ShowIcon})`,
    backgroundRepeat: 'no-repeat',
    minWidth: 20,
    height: 20,

    '&:hover': {
      filter: 'brightness(0.5)',
    },

    '&:disabled': {
      cursor: 'not-allowed',
      pointerEvents: 'auto',
    },
  },
  hideResultBtn: {
    border: 0,
    height: 4,
    width: 20,
    borderRadius: 4,
    cursor: 'pointer',
    backgroundColor: '#FE5000 !important',
    backgroundRepeat: 'no-repeat',
    minWidth: 20,
    padding: 0,

    '&:hover': {
      backgroundColor: '#B23800 !important',
    },
  },
  showCommentBtn: {
    border: 0,
    cursor: 'pointer',
    background: `url(${CommentIcon})`,
    backgroundRepeat: 'no-repeat',
    minWidth: 21,
    height: 22,

    '&:hover': {
      filter: 'brightness(0.5)',
    },
  },
  hideCommentBtn: {
    border: 0,
    cursor: 'pointer',
    '-webkit-mask-image': `url(${CommentIcon})`,
    maskImage: `url(${CommentIcon})`,
    maskSize: '21px 22px',
    backgroundColor: '#FE5000 !important',
    backgroundRepeat: 'no-repeat',
    minWidth: 21,
    height: 22,

    '&:hover': {
      backgroundColor: '#B23800 !important',
    },
  },
  btnBox: {
    padding: '20px 30px',
    display: 'flex',
    gap: 20,
    alignItems: 'center',
  },
  resultAndCommentRow: {
    background: '#F8F9FA',
  },
  showResultBox: {
    display: 'flex',
    gap: 5,
    padding: 16,
    border: '1px solid',
    borderRadius: 8,
    fontSize: 16,
    lineHeight: '24px',
    flexDirection: 'column',

    '& span:first-child': {
      fontWeight: 600,
    },
  },
  commentsBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,

    '& button': {
      height: 38,
      border: 'none',
      background: 'none',
    },
  },
  inputCommentBox: {
    paddingBottom: 12,
    borderTop: '1px solid #CFCFD4',
    paddingTop: 25,
  },
  commentsTitle: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '27px',
  },
  headerUserBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  userBox: {
    display: 'flex',
    gap: 8,

    '& span': {
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 600,

      '&:first-child': {
        color: '#FFFFFF',
        background: '#FE5000',
        borderRadius: '100%',
        width: 24,
        height: 24,
        textAlign: 'center',
      },
    },
  },
  dateTimeAndEditBox: {
    display: 'flex',
    gap: 16,

    '& span': {
      color: '#7B7F82',
      fontWeight: 500,
    },

    '& button': {
      backgroundColor: '#FE5000',
      '-webkit-mask-image': `url(${EditIcon})`,
      maskImage: `url(${EditIcon})`,
      minWidth: 16,
      height: 16,
      maskSize: 16,
      border: 0,
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: '#B23800',
      },
    },
  },
  commentBoxMain: {
    wordBreak: 'break-word',
    background: '#EFEFEF',
    border: '1px solid #CFCFD4',
    padding: 16,
    borderRadius: 8,
    fontSize: 16,
    lineHeight: '24px',
  },
  commentBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },
  commentAndEditBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  commentsListBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
  },
  commentsBoxMain: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  selectFileBtn: {
    background: `url(${AttachIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: 16,
    height: 16,
    backgroundSize: 'contain',
    border: 0,
    cursor: 'pointer',

    '&:hover': {
      filter: 'brightness(0.7)',
    },

    '&[aria-disabled="true"]': {
      background: 'rgba(0, 0, 0, 0.26) !important',
      maskImage: `url(${AttachIcon})`,
      maskSize: '16px 16px',
      cursor: 'auto',
    },
  },
  commentInput: {
    backgroundColor: '#FFFFFF',

    '& .MuiOutlinedInput-adornedEnd': {
      alignItems: 'end',
    },

    '& #file-upload': {
      display: 'none',
    },
  },
  labelCommentBox: {
    display: 'flex',
    gap: 6,
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 600,
    paddingBottom: 20,

    '& span:last-child': {
      color: '#FE5000',
    },
  },
  commentBtnBox: {
    display: 'flex',
    justifyContent: 'end',
    gap: 12,
    paddingTop: 12,

    '& button': {
      height: 40,
      borderRadius: 8,
      textTransform: 'capitalize',
      fontWeight: 600,

      '&.Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.36) !important',
        backgroundColor: 'rgba(0, 0, 0, 0.26) !important',
      },

      '&:first-child': {
        color: '#FE5000',
      },

      '&:last-child': {
        backgroundColor: '#FE5000',
        width: 140,
        color: '#FFFFFF',
      },
    },
  },
  documentsBox: {
    marginTop: 12,
  },
  documentChip: {
    marginLeft: 6,
    marginBottom: 6,
    borderColor: '#2296F3',

    '&.download': {
      '& .MuiChip-deleteIcon': {
        height: 20,
        width: 20,
        fontSize: 20,
      },

      '&:hover': {
        backgroundColor: 'rgb(34 150 243 / 15%)',
      },
    },

    '& .MuiChip-label': {
      color: '#2296F3',
      fontWeight: 500,
    },

    '& .MuiChip-deleteIcon': {
      color: '#2296F3',
    },

    '&.exceededFileSize': {
      borderColor: '#FF0000',

      '& .MuiChip-label': {
        color: '#FF0000',
      },

      '& .MuiChip-deleteIcon': {
        color: '#FF0000',
      },
    },
  },
  loading: {
    position: 'relative',
  },
  labelSecondaryCommentBox: {
    paddingBottom: 8,

    '& span': {
      fontSize: 12,
      display: 'flex',
      gap: 2,
    },
  },
  alertFileSize: {
    color: '#FF0000',
    fontSize: 12,
    paddingTop: 8,
    fontWeight: 500,
    display: 'flex',
    gap: 2,
  },
  b2eResultBox: {
    display: 'flex',
    gap: 20,
  },
  scoreBox: {
    display: 'flex',
    gap: 8,
  },
  dateBox: {
    display: 'flex',
    gap: 8,
  },
  reasonsBox: {
    display: 'flex',
    flexDirection: 'column',

    '& span:first-child': {
      fontWeight: 400,
    },
  },
  inputExtensionErrorContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
  inputExtensionErrorIcon: {
    padding: 0,
    color: '#F44336',
  },
  errorField: {
    color: '#F44336',
    fontWeight: 600,
    fontSize: '12px',
  },
}));

export default useStyles;
