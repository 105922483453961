// eslint-disable-next-line import/no-cycle
import { getFormValues } from 'redux-form';
import apiService from '../../../../shared/services/apiService';
import Types from './types';
import { getClient } from '../../../../shared/services/authClient';

const getOrdersAction = (codCliente, dataInicio, dataFim, rpp, pagina) => async (dispatch, getState) => {
  dispatch({ type: Types.GET_ORDERS_LIST_REQUEST });

  try {
    const filters = getFormValues('orders/filter')(getState());
    const client = getClient();

    const codClienteMatriz = client.codClienteMatriz || client.codCliente;
    const {
      codPedido
    } = filters;

    const {
      data: {
        meta,
        results,
      },
    } = await apiService.get(`pedidos/cliente/${codCliente}`,
      {
        params: {
          codClienteMatriz,
          codPedido,
          rpp,
          page: pagina,
          dataInicio: dataInicio,
          dataFim: dataFim,
        }
      }
    );

    dispatch({
      type: Types.GET_ORDERS_LIST_SUCCESS,
      payload: {
        orders: results,
        meta
      },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    dispatch({ type: Types.GET_ORDERS_LIST_FAILURE });
    snack.enqueueSnackbar(e.message || e, { variant: 'error' });
  }
};

const getOrdersByIdAction = (codPedido, codCliente) => async (dispatch, getState) => {
    dispatch({ type: Types.GET_ORDERS_BY_ID_REQUEST });

    try {
      const {
        data: {
        content,
        },
      } = await apiService.get(`/pedidos/cliente/${codCliente}/pedido/${codPedido}`);

    dispatch({
        type: Types.GET_ORDERS_BY_ID_SUCCESS,
        payload: { orderById : content },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    dispatch({ type: Types.GET_ORDERS_BY_ID_FAILURE });
    snack.enqueueSnackbar(e.message || e, { variant: 'error' });
  }
};

const clearOrderByIdAction = () => async (dispatch) => dispatch({ type: Types.CLEAR_ORDERS_BY_ID_FAILURE });

const sendPdfEmailAction = (codPedido, codCliente) =>
	async (dispatch, getState) => {
		dispatch({ type: Types.SEND_EMAIL_REQUEST });
    const { snack } = getState().main.app;

		try {
			const {
				data: { results },
			} = await apiService.post('relatorios/envio-por-email', {
				tipo: 'pedido',
				codCliente: codCliente,
				dadosRelatorio: [
					{
						uri: '/relatorios/pedidos/{codPedido}/detalhes',
						id: 'pedidoDetalhe',
						parameters: {
							parametro: 'codPedido',
							tipoParametro: 'url',
							valorParametro: codPedido,
						},
					},
				],
			});
			if (!results.response) throw new Error(results.message);
			snack.enqueueSnackbar(results.message, { variant: 'success' });

			dispatch({ type: Types.SEND_EMAIL_SUCCESS });
		} catch (_e) {
      snack.enqueueSnackbar("Ocorreu um erro ao enviar o e-mail", { variant: 'error' });
			dispatch({ type: Types.SEND_EMAIL_FAILURE });
		}
	};

  const getNfeDownloadAction = (idEntrega) => async (dispatch, getState) => {
    dispatch({ type: Types.GET_NFE_REQUEST });

    try {
      const { data } = await apiService.get(`nfe/download/${idEntrega}`, {
        responseType: 'blob',
      });

      const file = new Blob([data], { type: 'application/pdf' });

      window.open(URL.createObjectURL(file));
      dispatch({ type: Types.GET_NFE_SUCCESS });
    } catch (_e) {
      const { snack } = getState().main.app;
      snack.enqueueSnackbar("Ocorreu um erro buscar nota fiscal", { variant: 'error' });
      dispatch({ type: Types.GET_NFE_FAILURE });
    }
  };

  const newCheckoutAction = (codPedido) => async (dispatch, getState) => {
    dispatch({ type: Types.NEW_CHECKOUT_REQUEST });
    const { snack } = getState().main.app;
    const {user: { idVendedor }} = getState().auth.login;

    try {
      const {
        data: { content },
      } = await apiService.post(`comprar-novamente/pedido/${codPedido}/vendedor/${idVendedor}`);

      if (content) {
        snack.enqueueSnackbar('Carrinho criado com sucesso', { variant: 'success' });
        window.location.href = `/checkout/${content}` ;
      }

      dispatch({ type: Types.NEW_CHECKOUT_SUCCESS });
    } catch (_e) {
      snack.enqueueSnackbar("Ocorreu um erro recriar checkout", { variant: 'error' });
      dispatch({ type: Types.NEW_CHECKOUT_FAILURE });
    }
  };

export {
  getOrdersAction,
  getOrdersByIdAction,
  sendPdfEmailAction,
  getNfeDownloadAction,
  newCheckoutAction,
  clearOrderByIdAction
};
