import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import useStyles, { CollapseStyled } from './styles';
// eslint-disable-next-line import/no-cycle
import {
  HierarchicalMenu,
  NumericMenu,
  RefinementList,
  RefinementListCustom,
  Slider,
  ToggleRefinement,
} from '../index';

const Collapse = ({
  refinement,
  user,
  customer,
  handleSameFilialCheckbox,
}) => {
  const [open, setOpen] = useState(true);
  const classes = useStyles();

  const {
    title,
    isNumeric,
    isSlider,
    isToggle,
    isHierarchical,
    transformItems,
  } = refinement;

  const {
    collapseBox,
    collapseHeader,
  } = classes;

  const handleClick = () => setOpen(!open);

  const handleComponent = () => {
    if (isSlider) return <Slider refinement={refinement} />;
    if (isNumeric) return <NumericMenu refinement={refinement} />;
    if (isToggle) return <ToggleRefinement refinement={refinement} />;
    if (isHierarchical) return <HierarchicalMenu refinement={refinement} />;
    if (transformItems) {
      return (
        <RefinementListCustom
          user={user}
          customer={customer}
          refinement={refinement}
          handleSameFilialCheckbox={handleSameFilialCheckbox}
        />
      );
    }
    return (
      <RefinementList
        user={user}
        customer={customer}
        refinement={refinement}
        handleSameFilialCheckbox={handleSameFilialCheckbox}
      />
    );
  };

  return (
    <Box className={collapseBox}>
      <Box>
        <button type="button" onClick={handleClick} className={`${collapseHeader} ${open && 'rotateIcon'}`}>
          <span>{title}</span>
        </button>
      </Box>
      <CollapseStyled in={open}>
        { handleComponent() }
      </CollapseStyled>
    </Box>
  );
};

Collapse.defaultProps = {
  handleSameFilialCheckbox: null,
  customer: null,
};

Collapse.propTypes = {
  refinement: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  customer: PropTypes.instanceOf(Object),
  handleSameFilialCheckbox: PropTypes.instanceOf(Object),
};

export default Collapse;
