import {
  Button, Dialog, DialogActions,
  DialogContent, DialogTitle, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const DialogFileError = ({ open, onClose, children }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Arquivos permitidos</DialogTitle>
    <DialogContent>
      <Typography variant="body1">
        Planilhas:
      </Typography>
      <Typography variant="subtitle2">
        .gsheet (Google Sheets)
        <br />
        .xls (Excel, também compatível com LibreOffice)
        <br />
        .xlsx (Excel, LibreOffice, Google Sheets)
        <br />
        .xlsm (Excel, com macros)
        <br />
        .xlsb (Excel, formato binário)
        <br />
        .ods (LibreOffice/OpenOffice)
      </Typography>

      <br />
      <Typography variant="body1">
        Edição de Texto:
      </Typography>
      <Typography variant="subtitle2">
        .gdoc (Google Docs)
        <br />
        .doc (Microsoft Word, compatível com LibreOffice)
        <br />
        .docx (Microsoft Word, LibreOffice, Google Docs)
        <br />
        .odt (LibreOffice/OpenOffice)
        <br />
        .txt (TextPad, Notepad, texto simples)
      </Typography>

      <br />
      <Typography variant="body1">
        PDF:
      </Typography>
      <Typography variant="subtitle2">
        .pdf (Adobe Acrobat, e outros leitores de PDF)
      </Typography>
      {!!children && (children)}
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Fechar
      </Button>
    </DialogActions>
  </Dialog>
);

DialogFileError.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DialogFileError;
